import React, { Component, FormEvent, FunctionComponent } from "react";
import { Form, Alert, Col, Container, Row, Card } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Path, getPrimaryColor } from "../../env";
import { Button, variant } from "../form/Button";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { Shop } from "../../state/types";

export interface CredentialsState {
    termsAgreed: boolean;
    fields: Map<formAttribute, string>;
    error: string;
}

export interface CredentialsProps extends RouteComponentProps {
    auth: typeof Auth;
    shop?: Shop;
}

export enum formAttribute {
    username = "username",
    email = "email",
    password = "password",
}

export class Credentials extends Component<
    CredentialsProps,
    CredentialsState
> {
    constructor(props: CredentialsProps) {
        super(props);
        this.state = {
            termsAgreed: false,
            fields: new Map([
                [formAttribute.username, ""],
                [formAttribute.email, ""],
                [formAttribute.password, ""],
            ]),
            error: "",
        };
    }

    handleChange = (name: formAttribute, value: string) => {
        const fields = this.state.fields;
        fields.set(name, value);
        this.setState({
            fields: fields,
        });
    };

    isValidField = (
        field: formAttribute,
        fields: Map<formAttribute, string>
    ): boolean => {
        return (
            typeof fields.get(field) !== "undefined" &&
            fields.get(field)!.trim() !== ""
        );
    };

    handleNavigate = (path: Path) => {
        this.props.history.push(path);
    };

    isValidUsername = (value: string): boolean => {
        const regexPattern = /^[\p{Letter}\p{Mark}\p{Symbol}\p{Number}\p{Punctuation}]+$/u;
        return regexPattern.test(value);
    }

    handleSubmit = () => {
        if (
            this.isValidField(formAttribute.username, this.state.fields) ===
            false
        ) {
            const error = "Please provide your username";
            return this.setState({
                error: error,
            });
        }
        const username: string = this.state.fields
            .get(formAttribute.username)!
            .trim();

        if (!this.isValidUsername(username)) {
            return this.setState({
                error: "Username is invalid. Username cannot contain spaces and must be alphanumeric."
            })
        }

        if (
            this.isValidField(formAttribute.password, this.state.fields) ===
            false
        ) {
            const error = "Please provide a password";
            return this.setState({
                error: error,
            });
        }

        if (
            this.isValidField(formAttribute.email, this.state.fields) === false
        ) {
            const error = "Please provide your email";
            return this.setState({
                error: error,
            });
        }


        const password: string = this.state.fields
            .get(formAttribute.password)!
            .trim();
        const email: string = this.state.fields
            .get(formAttribute.email)!
            .trim();

        this.props.auth
            .signUp({
                username,
                password,
                attributes: {
                    email: email,
                },
            })
            .then(() => {
                this.props.history.push("/register/member/confirmation");
            })
            .catch((err) =>
                this.setState({
                    error: err.message,
                })
            );
    };

    handleAgreementChange = () => {
        this.setState({
            termsAgreed: !this.state.termsAgreed,
        })
    }

    render() {
        const credentialFormProps: CredentialsFormProps = {
            termsAgreed: this.state.termsAgreed,
            username: this.state.fields.get(formAttribute.username)!,
            email: this.state.fields.get(formAttribute.email)!,
            password: this.state.fields.get(formAttribute.password)!,
            error: this.state.error,
            shop: this.props.shop,
            onAgreementChange: this.handleAgreementChange,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
            onNavigate: this.handleNavigate,
        };
        return <CredentialsForm {...credentialFormProps} />;
    }
}

const mapState = (state: RootState) => ({
    shop: state.shop.shop,
});

const connector = connect(mapState, {});

export default connector(Credentials);

export interface CredentialsFormProps {
    termsAgreed: boolean;
    username: string;
    email: string;
    password: string;
    error: string;
    shop?: Shop;
    onAgreementChange: () => void;
    onChange: (name: formAttribute, value: string) => void;
    onSubmit: () => void;
    onNavigate: (path: Path) => void;
}

export const CredentialsForm: FunctionComponent<CredentialsFormProps> = (
    props
) => (
    <Container fluid>
        <Row>
            <Col style={{ margin: "auto" }} md={7} lg={6} xl={5}>
                <Card style={{ padding: "1rem" }}>
                    <Card.Body>
                        <div style={{ textAlign: "center" }}>
                            {(props.shop?.logo) && <img height={175} width={175} src={props.shop.logo} />}
                        </div>
                        <Card.Title style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}>Create an account</Card.Title>
                        <Form>
                            <Container>
                                <Row>
                                    <Col>
                                        <Alert variant={"danger"} show={props.error != ""}>
                                            {props.error}
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group style={{ marginTop: "1rem" }} controlId="formBasicUsername">
                                            <Form.Label>Username</Form.Label>
                                            <Form.Control
                                                id={"username"}
                                                type="text"
                                                name={"username"}
                                                placeholder="Enter username"
                                                onChange={(e) =>
                                                    props.onChange(formAttribute.username, e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ marginTop: "1rem" }} controlId="formBasicEmail">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control
                                                id={"email"}
                                                type="email"
                                                name={"email"}
                                                placeholder="Enter email"
                                                onChange={(e) =>
                                                    props.onChange(formAttribute.email, e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ marginTop: "1rem" }} controlId="formBasicPassword">
                                            <Form.Label>Password</Form.Label>
                                            <Form.Control
                                                id={"password"}
                                                type="password"
                                                name={"password"}
                                                placeholder="Password"
                                                onChange={(e) =>
                                                    props.onChange(formAttribute.password, e.target.value)
                                                }
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ paddingTop: "1.5rem", paddingLeft: "1rem", paddingRight: "1rem", paddingBottom: ".5rem" }} controlId="termsConfirmation">
                                            <Form.Check
                                                style={{ display: "inline-block" }}
                                                checked={props.termsAgreed}
                                                onClick={() => props.onAgreementChange()}
                                            />
                                            <Form.Label style={{ display: "inline-block", marginLeft: "1rem" }}>I agree to the <a href="https://ecomni.co.uk/terms.html">Terms and Conditions</a> and the <a href="https://ecomni.co.uk/privacy.html">Privacy Policy</a></Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row style={{ marginTop: "1rem" }}>
                                            <Col xs={12}>
                                                <Button style={{ width: "100%", marginBottom: "1.5rem" }} id={"register-btn"} onClick={() => props.onSubmit()} variant={variant.Primary} name={"Submit"} disabled={!props.termsAgreed} />
                                            </Col>
                                            <Col xs={12} style={{ textAlign: "center" }}>
                                                <span>Already a member? <a
                                                    style={{
                                                        color: getPrimaryColor(),
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        props.onNavigate(
                                                            Path.Login
                                                        )
                                                    }
                                                >
                                                    Login
                                                </a></span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);
