import { combineReducers } from "redux";
import { accountReducer } from "./account";
import { basketReducer } from "./basket";
import { activeShopReducer } from "./shop";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { journeyReducer } from "./journey";

const persistConfig = {
    key: 'root',
    storage,
}

export const rootReducer = combineReducers({
    basket: basketReducer,
    account: accountReducer,
    shop: activeShopReducer,
    journey: journeyReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer)

export type RootReducer = ReturnType<typeof persistedReducer>;