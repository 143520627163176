import { FunctionComponent } from "react";
import {
    Route,
    Switch,
    RouteComponentProps,
    BrowserRouter,
} from "react-router-dom";
import Navbar from "../components/menu/Navbar";
import { Footer } from "../components/menu/Footer";
import { Container } from "react-bootstrap";
import { Auth } from "aws-amplify";
import { Path } from "../env";
import Dashboard from "../components/dashboard/Dashboard";
import { GetCategories, GetDelivery, GetNearbyCollectionShops, GetProduct, GetProducts, GetShopContactDetails, InitPayment, SetOrderDeliveryDetails, SetUserOrderDeliveryDate, UpdateOrderStatus, UpdateShopCustomer, DeleteAccount as DeleteAccountAPI, GetCustomerFavorites, SetCustomerFavorite, DeleteCustomerFavorite, SendMessage } from "../client/core";
import Login from "../components/authentication/Login";
import Credentials from "../components/authentication/Credentials";
import Shop, { RouteShopProps } from "../components/shop/Shop";
import { useDispatch } from "react-redux";
import Basket from "../components/basket/Basket";
import Checkout from "../components/checkout/Checkout";
import { loadStripe } from "@stripe/stripe-js";
import PaymentConfirmation from "../components/checkout/PaymentConfirmation";
import OrderConfirmation from "../components/checkout/OrderConfirmation";
import CheckoutAsGuest from "../components/checkout/CheckoutAsGuest";
import { MemberRegisterConfirmation } from "../components/authentication/MemberRegisterConfirmation";
import ResetPassword from "../components/authentication/ResetPassword";
import Product from "../components/shop/Product";
import { DeleteAccount, DeleteAccountProps, DeleteConfirmationView } from "../components/authentication/DeleteAccount";
import SetAddress from "../components/actions/SetAddress";

export const Routes: FunctionComponent<{}> = () => {
    const dispatch = useDispatch()
    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path={Path.Home}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <Dashboard
                                {...props}
                                auth={Auth}
                                getNearbyShopsAPI={new GetNearbyCollectionShops()}
                                basketDispatch={dispatch}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.Dashboard}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <Dashboard
                                {...props}
                                auth={Auth}
                                getNearbyShopsAPI={new GetNearbyCollectionShops()}
                                basketDispatch={dispatch}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.Login}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <Login
                                {...props}
                                auth={Auth}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.ResetPassword}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <ResetPassword
                                {...props}
                                auth={Auth}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.RegisterMember}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <Credentials
                                {...props}
                                auth={Auth}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.RegisterConfirmation}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <MemberRegisterConfirmation
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={`${Path.Shop}/:shopId`}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <Shop
                                {...props as RouteShopProps}
                                auth={Auth}
                                getProductsAPI={new GetProducts()}
                                basketDispatch={dispatch}
                                activeShopDispatch={dispatch}
                                accountDispatch={dispatch}
                                journeyDispatch={dispatch}
                                getShopContactAPI={new GetShopContactDetails()}
                                getCategoriesAPI={new GetCategories()}
                                getFavoritesAPI={new GetCustomerFavorites()}
                                setFavoriteAPI={new SetCustomerFavorite()}
                                deleteFavoriteAPI={new DeleteCustomerFavorite()}
                                sendShopMessageAPI={new SendMessage()}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.SetAddress}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <SetAddress {...props} auth={Auth} accountDispatch={dispatch} />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.AccountDelete}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <DeleteAccount {...props} auth={Auth} deleteAccountAPI={new DeleteAccountAPI()}  />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.AccountDeleteConfirmation}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <DeleteConfirmationView />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={`${Path.Shop}/:shopId${Path.Product}/:productId`}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <Product
                                {...props}
                                auth={Auth}
                                getProductAPI={new GetProduct()}
                                basketDispatch={dispatch}
                                activeShopDispatch={dispatch}
                                shopsDispatch={dispatch}
                                getShopContactAPI={new GetShopContactDetails()}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.Basket}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <Basket
                                {...props as RouteShopProps}
                                basketDispatch={dispatch}
                                getDeliveryAPI={new GetDelivery()}
                                accountDispatch={dispatch}
                                getShopContactAPI={new GetShopContactDetails()}
                                shopDispatch={dispatch}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.Checkout}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <Checkout
                                {...props as RouteShopProps}
                                auth={Auth}
                                initPaymentAPI={new InitPayment()}
                                updateOrderStatusAPI={new UpdateOrderStatus()}
                                setOrderDeliveryDetailsAPI={new SetOrderDeliveryDetails()}
                                loadStripe={loadStripe}
                                basketDispatch={dispatch}
                                setUserOrderDeliveryDateAPI={new SetUserOrderDeliveryDate()}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.PaymentConfirmation}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <PaymentConfirmation
                                {...props as RouteShopProps}
                                basketDispatch={dispatch}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.OrderConfirmation}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <OrderConfirmation
                                {...props as RouteShopProps}
                            />
                        </Screen>
                    }
                />
                <Route
                    exact
                    path={Path.CheckoutAsGuest}
                    render={(props) =>
                        <Screen
                            {...props}
                        >
                            <CheckoutAsGuest
                                accountDispatch={dispatch}
                                auth={Auth}
                                updateShopCustomer={new UpdateShopCustomer()}
                                {...props as RouteShopProps}
                            />
                        </Screen>
                    }
                />
            </Switch>
        </BrowserRouter>
    )
}

export interface ScreenProps extends RouteComponentProps {
    children?: JSX.Element | JSX.Element[];
}

export const Screen: FunctionComponent<ScreenProps> = props => (
    <div>
        <Navbar auth={Auth} {...props} />
        <Container fluid style={{ marginTop: "7rem", marginBottom: "4rem" }}>{props.children}</Container>
        <Footer />
    </div>
)