export interface JourneyState {
    journey?: Journey
}

export interface Journey {
    // The base page that overrides returning to the dashboard if present
    base?: string;
}

export const UPDATE_JOURNEY = "UPDATE_JOURNEY";

export interface JourneyAction {
    type: string;
    payload?: Journey;
}