import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { Container, Nav, Navbar as BNavbar } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Color, Path, Storage, getPrimaryColor } from "../../env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBasketShopping, faSignIn, faSignOut } from '@fortawesome/free-solid-svg-icons'
import { RootState } from "../../state";
import { connect, useSelector } from "react-redux";
import { Shop } from "../../state/types/shop";

export interface NavbarState {
    isMobile: boolean;
    isLoggedIn: boolean;
}

export interface NavbarProps extends RouteComponentProps {
    auth: typeof Auth;
    shop?: Shop;
}

export class Navbar extends Component<NavbarProps, NavbarState> {
    constructor(props: NavbarProps) {
        super(props);
        this.state = {
            isMobile: false,
            isLoggedIn: localStorage.getItem(Storage.IsLoggedIn) != null,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        this.setState({
            isMobile: window.innerWidth <= 760
        });
    }

    componentDidUpdate() {
        const isLoggedIn = localStorage.getItem(Storage.IsLoggedIn) != null;
        if (this.state.isLoggedIn === isLoggedIn) {
            return;
        }
        this.setState({
            isLoggedIn: isLoggedIn,
        });
    }

    handleSelect = (path: string) => {
        this.props.history.push(path);
    };

    handleLogout = async () => {
        await this.props.auth.signOut()
        localStorage.removeItem(Storage.IsLoggedIn);
        return this.props.history.push(Path.Login);
    };

    isEcomni = (): boolean => {
        return (this.props.shop === undefined)
    }

    render() {
        const props: NavbarViewProps = {
            onSelect: this.handleSelect,
            onLogout: this.handleLogout,
            shopName: (this.isEcomni()) ? "" : this.props.shop?.name || "",
            logo: (this.isEcomni()) ? "" : this.props.shop?.logo || "",
            home: (this.isEcomni()) ? Path.Dashboard : `${Path.Shop}/${this.props.shop?.id}`,
        };
        if (this.state.isLoggedIn) {
            if (this.state.isMobile) {
                return <MobileShopAdminNavbar {...props} />
            } else {
                return <DesktopShopAdminNavbar {...props} />
            }
        } else {
            if (this.state.isMobile) {
                return <UnauthenticatedMobileNavbar {...props} />
            } else {
                return <UnauthenticatedNavbar {...props} />
            }
        }
    }
}

const mapState = (state: RootState) => ({
    shop: state.shop.shop,
});

const connector = connect(mapState, {});

export default connector(Navbar);

export interface NavbarViewProps {
    shopName: string
    logo: string
    home: string
    onSelect: (path: string) => void;
    onLogout: () => void;
}

export const DesktopShopAdminNavbar: FunctionComponent<NavbarViewProps> = (props) => (
    <BNavbar className="elevate" style={{ zIndex: 8, background: Color.White, paddingLeft: ".5rem" }} variant="light" fixed="top">
        <Container fluid>
            <BNavbar.Brand
                style={{ cursor: "pointer" }}
                onClick={() => props.onSelect(props.home)}
            >
                {(props.logo !== "") ? <img style={{ display: "inline-block", marginRight: ".5rem" }} height={50} width={50} src={props.logo} /> : <ReactSVG style={{ display: "inline-block", marginRight: ".5rem" }} src={"/ecomni.svg"} />}
                {(props.shopName !== "") ? <span style={{ fontSize: "1.1rem", fontWeight: 700, color: getPrimaryColor() }}>{props.shopName}</span> : <span style={{ fontSize: "1.1rem", fontWeight: 700, color: getPrimaryColor() }}>Stumbled</span>}
            </BNavbar.Brand>
            <Nav style={{ textAlign: "center" }} className="me-auto">
            </Nav>
            <Nav className="justify-content-right">
                <BasketLinkView {...props} />
                <Nav.Link style={{ color: "black" }} onClick={() => props.onLogout()}>
                    <FontAwesomeIcon name="Logout" color={getPrimaryColor()} icon={faSignOut} />
                </Nav.Link>
            </Nav>
        </Container>
    </BNavbar>
);

export const MobileShopAdminNavbar: FunctionComponent<NavbarViewProps> = (props) => (
    <BNavbar style={{ zIndex: 8, background: Color.White, borderBottom: "1px lightgrey solid", paddingLeft: ".5rem", paddingRight: ".5rem" }} collapseOnSelect expand="lg" variant="light" fixed="top">
        <BNavbar.Brand
            style={{ cursor: "pointer" }}
            onClick={() => props.onSelect(props.home)}
        >
            {(props.logo !== "") ? <img style={{ display: "inline-block", marginRight: ".5rem" }} height={50} width={50} src={props.logo} /> : <ReactSVG style={{ display: "inline-block", marginRight: ".5rem" }} src={"/ecomni.svg"} />}
        </BNavbar.Brand>
        <BNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BNavbar.Collapse id="responsive-navbar-nav">
            <Nav style={{ textAlign: "center" }}>
                <Nav.Link style={{ color: Color.DarkGrey }} onClick={() => props.onSelect(Path.Basket)}>Basket</Nav.Link>
                <Nav.Link style={{ color: Color.DarkGrey }} onClick={() => props.onLogout()}>Logout</Nav.Link>
            </Nav>
        </BNavbar.Collapse>
    </BNavbar>
);

export const UnauthenticatedMobileNavbar: FunctionComponent<NavbarViewProps> = (
    props
) => (
    <BNavbar className="elevate" style={{ zIndex: 8, background: Color.White, paddingLeft: ".5rem" }} variant="light" fixed="top">
        <Container fluid>
            <BNavbar.Brand
                style={{ cursor: "pointer" }}
                onClick={() => props.onSelect(props.home)}
            >
                {(props.logo !== "") ? <img style={{ display: "inline-block", marginRight: ".5rem" }} height={50} width={50} src={props.logo} /> : <ReactSVG style={{ display: "inline-block", marginRight: ".5rem" }} src={"/ecomni.svg"} />}
            </BNavbar.Brand>
            <Nav className="justify-content-end">
                <BasketLinkView {...props} />
                <Nav.Link style={{ color: "black" }} onClick={() => props.onSelect(Path.Login)}>
                    <FontAwesomeIcon name="Login" size="lg" color={getPrimaryColor()} icon={faSignIn} />
                </Nav.Link>
            </Nav>
        </Container>
    </BNavbar>
);

export const UnauthenticatedNavbar: FunctionComponent<NavbarViewProps> = (
    props
) => (
    <BNavbar className="elevate" style={{ zIndex: 8, background: Color.White, paddingLeft: ".5rem" }} variant="light" fixed="top">
        <Container fluid>
            <BNavbar.Brand
                style={{ cursor: "pointer" }}
                onClick={() => props.onSelect(props.home)}
            >
                {(props.logo !== "") ? <img style={{ display: "inline-block", marginRight: ".5rem" }} height={50} width={50} src={props.logo} /> : <ReactSVG style={{ display: "inline-block", marginRight: ".5rem" }} src={"/ecomni.svg"} />}
                {(props.shopName !== "") ? <span style={{ fontSize: "1.1rem", fontWeight: 700, color: getPrimaryColor() }}>{props.shopName}</span> : <span style={{ fontSize: "1.1rem", fontWeight: 700, color: getPrimaryColor() }}>Stumbled</span>}
            </BNavbar.Brand>
            <Nav className="justify-content-end">
                <BasketLinkView {...props} />
                <Nav.Link style={{ color: "black" }} onClick={() => props.onSelect(Path.Login)}>
                    <FontAwesomeIcon name="Login" size="lg" color={getPrimaryColor()} icon={faSignIn} />
                </Nav.Link>
            </Nav>
        </Container>
    </BNavbar>
);

export const BasketLinkView: FunctionComponent<NavbarViewProps> = (
    props
) => {
    const numberOfItems = useSelector((state: RootState) => state.basket.items.filter(item => item.shopId === state.shop.shop?.id).length)
    return (
        <Nav.Link style={{ color: "black", marginRight: "1rem" }} onClick={() => props.onSelect(Path.Basket)}>
            <FontAwesomeIcon name="Basket" size="lg" color={getPrimaryColor()} icon={faBasketShopping} />
            {(numberOfItems > 0) && <span style={{ width: "1.2rem", height: "1.2rem", position: "absolute", background: Color.Red, borderRadius: "100%", textAlign: "center", fontSize: ".7rem", marginTop: "-.4rem", color: Color.White }}>{numberOfItems}</span>}
        </Nav.Link>
    )
}