import { Auth } from "aws-amplify";
import { Component, Dispatch, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { AccountAction, Address as AddressDto, UPDATE_ACCOUNT } from "../../state/types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import { Color, getPrimaryColor } from "../../env";

export interface AddressState { }

export type AccountDispatch = Dispatch<AccountAction>;

export interface AddressProps extends RouteComponentProps {
    auth: typeof Auth;
    accountDispatch: AccountDispatch;
    onSubmit?: (address: AddressDto) => void;
}

export default class Address extends Component<AddressProps, AddressState> {
    handleAddressSubmit = (place: google.maps.places.PlaceResult): void => {
        this.props.accountDispatch({
            type: UPDATE_ACCOUNT,
            payload: {
                data: {
                    address: {
                        value: place.formatted_address!,
                        metadata: {
                            id: place.place_id!,
                        }
                    }
                }
            }
        })
        if (this.props.onSubmit) {
            this.props.onSubmit({
                value: place.formatted_address!,
                metadata: {
                    id: place.place_id!,
                }
            })
        }
    }

    render() {
        const props: AddressViewProps = {
            onAddressSubmit: this.handleAddressSubmit,
            placeApiKey: process.env.REACT_APP_CORE_GOOGLE_PLACES_KEY!,
        };
        return <AddressForm {...props} />
    }
}

export interface AddressViewProps {
    placeApiKey: string
    onAddressSubmit: (place: google.maps.places.PlaceResult) => void
}

export const AddressForm: FunctionComponent<AddressViewProps> = (props) => (
    <div style={{position: "relative"}}>
        <Autocomplete
            apiKey={props.placeApiKey}
            onPlaceSelected={(place) => props.onAddressSubmit(place)}
            style={{
                width: "100%",
                padding: ".375rem .75rem",
                borderRadius: ".25rem",
                border: "1px solid #ced4da",
            }}
            options={{
                types: ["geocode", "establishment"],
                componentRestrictions: {
                    country: "uk"
                },
            }}
            placeholder="Enter your address e.g. 221B Baker Street, London"
        />
        <OverlayTrigger
            placement="bottom"
            delay={{ show: 150, hide: 300 }}
            overlay={props => (
                <Tooltip id="button-tooltip" {...props}>
                    For flats just enter the building name and flat numbers can go in the delivery details.
                </Tooltip>
            )}
        >
            <InfoCircleFill style={{ marginLeft: ".5rem", position: "absolute", right: 10, top: 6 }} color={getPrimaryColor()} size={25} />
        </OverlayTrigger>
    </div>
);