import { Storage } from "../../env";
import { Shop, ActiveShopAction, ActiveShopState as ActiveShopState, ShopType, UPDATE_SHOP } from "../types/shop";

const INITIAL_STATE: ActiveShopState = {};

export const activeShopReducer = (state = INITIAL_STATE, action: ActiveShopAction): ActiveShopState => {
    switch (action.type) {
        case UPDATE_SHOP:
            if (action.payload?.color && action.payload.color !== "") {
                localStorage.setItem(Storage.ShopColor, action.payload.color)
            } else {
                localStorage.removeItem(Storage.ShopColor)
            }
            return {
                shop: action.payload
            }
        default:
            return state;
    }
};
