import { FormElement } from "../../client/core";

export interface ActiveShopState {
    shop?: Shop;
}

export interface ShopsState {
    shops?: Shop[];
}

export enum ShopType {
    Delivery = "delivery",
    Collection = "collection"
}

export interface Shop {
    id: string
    name: string
    address: string
    addressId: string
    phone: string
    email: string
    contacts: ShopContact[]
    isDelivery: boolean
    isCollection: boolean
    logo?: string
    color?: string
    paymentInstructions?: string
    paymentForm?: FormElement[]
    // Town, city, etc
    area: string
    active: boolean
}

export interface ShopContact {
    address: string
    addressId: string
}

export const UPDATE_SHOP = "UPDATE_SHOP";
export const UPDATE_SHOPS = "UPDATE_SHOPS";

export interface ActiveShopAction {
    type: string;
    payload?: Shop;
}

export interface ShopsAction {
    type: string;
    payload?: Shop[];
}
