import { FunctionComponent } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Color, Path, getPrimaryColor } from "../../env";

export interface FooterProps { }

export const Footer: FunctionComponent<FooterProps> = (props) => (
    <div style={{ background: Color.White, fontWeight: 500, position: "fixed", bottom: 0, width: "100%", zIndex: 9 }}>
        <Container fluid style={{ textAlign: "center", fontSize: ".8rem" }}>
            <Row style={{ padding: ".2rem" }}>
                <Col>
                    <div style={{ textAlign: "center" }}>
                        <li style={{ display: "inline-block", marginRight: ".5rem", marginLeft: ".5rem" }}><a href={"https://stumbled.online/contact.html"} target={"_blank"} style={{ color: getPrimaryColor() }}>Contact us</a></li>
                    </div>
                </Col>
                <Col>
                    <div style={{ textAlign: "center" }}>
                        <li style={{ display: "inline-block", marginRight: ".5rem", marginLeft: ".5rem", color: getPrimaryColor() }}>© 2023 <a href={"https://stumbled.online"} target={"_blank"} style={{ color: getPrimaryColor(), textDecoration: "none" }}>Stumbled</a></li>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
);