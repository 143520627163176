import { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Alert, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Button, variant } from "../form/Button";
import { Path } from "../../env";
import { IDeleteAccount } from "../../client/core";

export interface DeleteAccountState {
    error: string;
    email: string;
    username: string;
    password: string;
}

export interface DeleteAccountProps extends RouteComponentProps {
    auth: typeof Auth;
    deleteAccountAPI: IDeleteAccount;
}

export class DeleteAccount extends Component<DeleteAccountProps, DeleteAccountState> {
    constructor(props: DeleteAccountProps) {
        super(props);
        this.state = {
            error: "",
            email: "",
            username: "",
            password: "",
        };
    }

    isLoggedIn = async (): Promise<boolean> => {
        try {
            await this.props.auth.currentAuthenticatedUser();
            return true;
        } catch {
            return false;
        }
    }

    componentDidMount(): void {
        this.props.auth.currentAuthenticatedUser().then(user => {
            console.log(user)
            this.setState({
                email: user.email,
                username: user.username,
            })
        }).catch(err => {
            return this.props.history.push(Path.Login)
        })
    }

    handleSubmit = async () => {
        if (!this.isLoggedIn) {
            return this.props.history.push(Path.Login)
        }

        const isPasswordMatch = await this.checkPasswordMatch(this.state.password)

        if (!isPasswordMatch) {
            this.setState({
                error: "The password you have used does not match the account logged in."
            })
        }

        const session = await this.props.auth.currentSession()

        this.props.deleteAccountAPI.DeleteAccount({
            identityToken: session.getIdToken().getJwtToken(),
        }).then(response => {
            if (response.statusCode != 200) {
                return this.setState({
                    error: "Sorry, we were unable to delete your account at this time. If this issue persists please contact: support@stumbled.online."
                })
            }
        }).catch(err => {
            return this.setState({
                error: "Sorry, we were unable to delete your account at this time. If this issue persists please contact: support@stumbled.online."
            })
        })

        return this.props.history.replace(Path.AccountDeleteConfirmation)
    };

    checkPasswordMatch = async (inputPassword: string): Promise<boolean> => {
        try {
            const isPasswordMatch = await Auth.signIn(this.state.password, inputPassword);
            return isPasswordMatch;
        } catch (error) {
            this.props.history.push(Path.Login)
            return false
        }
    };

    render() {
        const credentialFormProps: DeleteAccountViewProps = {
            error: this.state.error,
            username: this.state.username,
            onSubmit: this.handleSubmit
        };
        return <DeleteAccountView {...credentialFormProps} />;
    }
}

export interface DeleteAccountViewProps {
    error: string;
    username: string;
    onSubmit: () => void;
}

export const DeleteAccountView: FunctionComponent<DeleteAccountViewProps> = (props) => (
    <Container fluid>
        <Row>
            <Col style={{ margin: "auto" }} xs={12} md={7} lg={6} xl={5}>
                <Card style={{ padding: "1rem" }}>
                    <Card.Body>
                        <Card.Title style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}>Delete Account</Card.Title>
                        <Card.Text>
                            <p>{`Hi ${props.username}, we're sorry to see you go. Before you proceed with deleting your account, we want to express our gratitude for being a part of our community.`}</p>
                            <p>If you're sure about deleting your account, please take a moment to consider the following:</p>
                            <ul>
                                <li>All your account data, including preferences and settings, will be permanently deleted.</li>
                                <li>You won't be able to access your account or retrieve any information once it's deleted.</li>
                                <li>If you change your mind later, you'll need to create a new account.</li>
                            </ul>
                            <p>If you still wish to proceed, enter your password below to confirm the account deletion.</p>
                        </Card.Text>
                        <Form>
                            <Container>
                                <Row>
                                    <Col>
                                        <Alert
                                            variant={"danger"}
                                            show={props.error != ""}
                                        >
                                            {props.error}
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "1rem" }}>
                                    <Col>
                                        <Form.Group controlId="formBasicUsername">
                                            <Form.Label>Please enter your password.</Form.Label>
                                            <Form.Control
                                                id={"password"}
                                                type="password"
                                                name={"password"}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "1rem" }}>
                                    <Col>
                                        <Button id={"submit-btn"} style={{ width: "100%", marginTop: "1rem" }} name="Delete Account" variant={variant.Danger} onClick={props.onSubmit} />
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);

export interface DeleteConfirmationViewProps { }

export const DeleteConfirmationView: FunctionComponent<DeleteConfirmationViewProps> = (props) => (
    <Container fluid>
        <Row>
            <Col style={{ margin: "auto" }} xs={12} md={7} lg={6} xl={5}>
                <Card style={{ padding: "1rem" }}>
                    <Card.Body>
                        <Card.Title style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}>Account Deleted</Card.Title>
                        <Card.Text>
                            <p>Your account has been successfully deleted.</p>
                            <p>We're sorry to see you go, and we appreciate the time you spent with us. If there's anything you'd like to share about your experience or if you have any feedback, please feel free to reach out.</p>
                            <p>Remember, you can always come back and create a new account if you decide to rejoin our community in the future.</p>
                            <p>Thank you, and we wish you all the best on your journey!</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);
