import { Auth } from "aws-amplify";
import { Component, Dispatch, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { GetNearbyDeliveryShops, IGetNearbyShops, ShopSummary } from "../../client/core";
import NearbyShops from "../nearbyshops/NearbyShops";
import { ButtonGroup, Card, Col, Container, Row, ToggleButton } from "react-bootstrap";
import Address from "../address/Address";
import { connect, useDispatch } from "react-redux";
import { BasketAction, BasketType, UPDATE_TYPE } from "../../state/types";
import { RootState } from "../../state";


export type BasketDispatch = Dispatch<BasketAction>;

export interface DashboardState {
    shops: ShopSummary[]
}

export interface DashboardProps extends RouteComponentProps {
    getNearbyShopsAPI: IGetNearbyShops
    auth: typeof Auth;
    basketType: BasketType
    basketDispatch: BasketDispatch
}

export class Dashboard extends Component<
    DashboardProps,
    DashboardState
> {
    constructor(props: DashboardProps) {
        super(props);
        this.state = {
            shops: [],
        };
    }

    handleOrderTypeChange = (type: BasketType) => {
        this.props.basketDispatch({
            type: UPDATE_TYPE,
            payload: type,
        })
    }

    render() {
        const props: DashboardViewProps = {
            shops: this.state.shops,
            onBasketTypeChange: this.handleOrderTypeChange,
            ...this.props
        }
        return <DashboardView {...props} />;
    }
}

const mapState = (state: RootState) => ({
    basketType: state.basket.type,
});

const connector = connect(mapState, {});

export default connector(Dashboard);

export interface DashboardViewProps extends RouteComponentProps {
    shops: ShopSummary[]
    basketType: BasketType
    onBasketTypeChange: (type: BasketType) => void
}

export const DashboardView: FunctionComponent<DashboardViewProps> = (
    props
) => (
    <Container fluid>
        <Card>
            <Card.Body>
                <Row>
                    <Col xs={12}>
                        <Card.Title>Discover</Card.Title>
                        <Card.Text>Discover local shops in your area.</Card.Text>
                    </Col>
                    <Col xs={12} style={{ textAlign: "center", marginBottom: "1rem", marginTop: "1rem" }}>
                        <ButtonGroup>
                            <OrderTypeSelection basketType={props.basketType} onChange={props.onBasketTypeChange} />
                        </ButtonGroup>
                    </Col>
                </Row>
                <Address accountDispatch={useDispatch()} auth={Auth} {...props} />
                <div style={{ marginTop: "1rem" }}>
                    <NearbyShops getNearbyShopsAPI={new GetNearbyDeliveryShops()} title={"Shops that deliver"} />
                </div>
            </Card.Body>
        </Card>
    </Container>
)

export interface OrderTypeSelectionProps {
    basketType: BasketType
    onChange: (type: BasketType) => void
}

export const OrderTypeSelection: FunctionComponent<OrderTypeSelectionProps> = props => (
    <>
        <ToggleButton
            key={"collection"}
            type="radio"
            variant={(props.basketType === BasketType.Collection) ? 'outline-success' : "outline-secondary"}
            name="radio-collection"
            value={BasketType.Collection}
            checked={props.basketType === BasketType.Collection}
            onChange={() => props.onChange(BasketType.Collection)}
            onClick={() => props.onChange(BasketType.Collection)}
            style={{borderRadius: 0}}
        >
            Click & Collect
        </ToggleButton>
        <ToggleButton
            key={"delivery"}
            type="radio"
            variant={(props.basketType === BasketType.Delivery) ? 'outline-success' : "outline-secondary"}
            name="radio-delivery"
            value={BasketType.Delivery}
            checked={props.basketType === BasketType.Delivery}
            onChange={() => props.onChange(BasketType.Delivery)}
            onClick={() => props.onChange(BasketType.Delivery)}
            style={{borderRadius: 0}}
        >
            Delivery
        </ToggleButton>
    </>
)