import { Component, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { DELETE_ITEM, BasketAction, BasketItem, BasketType, UPDATE_TYPE, ADD_ITEM, Address as AddressDto, AccountAction } from "../../state/types";
import { Dispatch } from "redux";
import { ButtonGroup, Card, Col, ListGroup, Modal, Row, ToggleButton } from "react-bootstrap";
import { ActiveShopAction, Shop, UPDATE_SHOP } from "../../state/types/shop";
import { Button, variant } from "../form/Button";
import { Color, Path, getPrimaryColor } from "../../env";
import { FaBicycle, FaShoppingBag } from "react-icons/fa";
import { Button as BButton } from "react-bootstrap"
import { Delivery, IGetDelivery, IGetShopContactDetails, ShopContact } from "../../client/core";
import { Error as ErrorView } from "../form/Error";
import { RouteShopProps } from "../shop/Shop";
import Address from "../address/Address";
import { Auth } from "aws-amplify";

type TParams = { shopId: string };

export type BasketDispatch = Dispatch<BasketAction>;

export type RouteBasketProps = RouteComponentProps<TParams>;

export type ShopDispatch = Dispatch<ActiveShopAction>;

export type AccountDispatch = Dispatch<AccountAction>;

export interface BasketProps extends RouteBasketProps {
    basketDispatch: BasketDispatch;
    shopDispatch: ShopDispatch;
    accountDispatch: AccountDispatch;
    getShopContactAPI: IGetShopContactDetails;
    getDeliveryAPI: IGetDelivery;
    basket: BasketItem[];
    shop?: Shop;
    basketType: BasketType;
    address?: AddressDto;
}

export enum Action {
    None,
    ChangeDelivery
}

export interface BasketState {
    error: string;
    delivery?: Delivery
    action: Action
}

export class Basket extends Component<
    BasketProps,
    BasketState
> {
    constructor(props: BasketProps) {
        super(props);
        this.state = {
            error: "",
            action: Action.None
        };
    }

    componentDidMount(): void {
        if (!this.props.shop?.isDelivery) {
            return this.handleBasketTypeChange(BasketType.Collection)
        }

        this.getDeliveryDetails()
    }

    handleDeliveryAddressSubmit = async (address: AddressDto) => {
        await this.getShopSummary(address)
        this.getDeliveryDetails()
    }

    getDeliveryDetails = () => {
        this.props.getDeliveryAPI.GetDelivery({
            shopID: this.props.shop?.id!
        }).then(response => {
            if (response.statusCode !== 200) {
                return this.setState({
                    error: "Sorry, we could not retrieve shops delivery details at the time."
                })
            }

            this.setState({
                delivery: response.delivery,
            })
        }).catch(err => {
            this.setState({
                error: "Sorry, we could not retrieve shops delivery details at the time."
            })
        })
    }

    getShopSummary = async (address: AddressDto) => {
        const response = await this.props.getShopContactAPI.GetShopContactDetails({
            shopId: this.props.shop?.id!,
            address: {
                address: address.value,
                id: address.metadata.id,
            },
        })

        if (response instanceof Error) {
            return this.props.shopDispatch({
                type: "UPDATE_SHOP",
                payload: {
                    ...this.props.shop!,
                    isDelivery: false,
                }
            })
        }

        return this.props.shopDispatch({
            type: "UPDATE_SHOP",
            payload: {
                ...this.props.shop!,
                isDelivery: response.body?.isDelivery || false
            }
        })
    }

    handleDeleteItem = (item: BasketItem): void => {
        this.props.basketDispatch({
            type: DELETE_ITEM,
            payload: {
                ...item
            }
        })
    }

    filterItemsByShopId = (id: string, items: BasketItem[]): BasketItem[] => {
        if (id === "") {
            return []
        }
        return items.filter(item => item.shopId === id)
    }

    handleNavigate = (path: string) => {
        this.props.history.push(path);
    };

    handleBasketTypeChange = (type: BasketType): void => {
        this.props.basketDispatch({
            type: UPDATE_TYPE,
            payload: type,
        })
    }

    handleItemChange = (item: BasketItem) => {
        item.price = (item.volume && item.volume > 0) ? (Math.round((item.volume / (item.productVolume || 1))) * (item.productPrice * 100)) / 100 : 0
        this.props.basketDispatch({
            type: ADD_ITEM,
            payload: item,
        })
    }

    handleChangeAction = (action: Action): void => {
        this.setState({
            action: action,
        })
    }

    handleContactChange = (contact: ShopContact) => {
        this.props.shopDispatch({
            type: UPDATE_SHOP,
            payload: {
                ...this.props.shop!,
                address: contact.address,
                addressId: contact.addressId
            }
        })
    }

    render() {
        const basketViewProps: BasketViewProps = {
            ...this.props,
            error: this.state.error,
            items: this.filterItemsByShopId(this.props.shop?.id || "", this.props.basket),
            shop: this.props.shop,
            basketType: this.props.basketType,
            delivery: this.state.delivery,
            address: this.props.address,
            action: this.state.action,
            auth: Auth,
            onItemChange: this.handleItemChange,
            onDeleteItem: this.handleDeleteItem,
            onBasketTypeChange: this.handleBasketTypeChange,
            onNavigate: this.handleNavigate,
            onAddressSubmit: this.handleDeliveryAddressSubmit,
            onActionChange: this.handleChangeAction,
            onCollectionChange: this.handleContactChange,
        };
        return <BasketView {...basketViewProps} />
    }
}

const mapState = (state: RootState) => ({
    basket: state.basket.items,
    shop: state.shop.shop,
    basketType: state.basket.type,
    address: state.account.data?.address,
});

const connector = connect(mapState, {});

export default connector(Basket);

export interface BasketViewProps extends RouteShopProps {
    error: string;
    basketType: BasketType;
    items: BasketItem[];
    shop?: Shop;
    delivery?: Delivery;
    address?: AddressDto;
    action: Action;
    auth: typeof Auth;
    accountDispatch: AccountDispatch;
    onDeleteItem: (item: BasketItem) => void
    onNavigate: (path: string) => void;
    onBasketTypeChange: (type: BasketType) => void;
    onItemChange: (item: BasketItem) => void
    onActionChange: (action: Action) => void
    onAddressSubmit: (address: AddressDto) => void;
    onCollectionChange: (contact: ShopContact) => void;
}

export const BasketView: FunctionComponent<BasketViewProps> = (
    props
) => {
    const itemTotal: number = getItemTotal(props.items)
    const deliveryTotal: number = (props.basketType === BasketType.Delivery) ? props.delivery?.price || 0 : 0
    const isOverMinOrder = (props.basketType === BasketType.Collection) || (props.delivery && (itemTotal + deliveryTotal) > props.delivery.minimumPrice)
    return (
        <Row>
            <Modal show={props.action == Action.ChangeDelivery} onHide={() => props.onActionChange(Action.None)}>
                <Modal.Header closeButton>
                    Change Delivery Address
                </Modal.Header>
                <Modal.Body>
                    <Address {...props} onSubmit={address => props.onAddressSubmit(address)} />
                    <Button style={{ width: "100%", marginTop: "2rem" }} name={"Continue"} variant={variant.Primary} color={Color.Primary} onClick={() => props.onActionChange(Action.None)} />
                </Modal.Body>
            </Modal>
            <Col style={{ padding: "1rem 2rem 0 2rem" }}>
                <div className="d-none d-sm-block"><BasketItemsView onNavigate={props.onNavigate} shop={props.shop} onItemChange={props.onItemChange} onDeleteItem={props.onDeleteItem} items={props.items} /></div>
                <div className="d-sm-none"><MobileBasketItemsView onNavigate={props.onNavigate} shop={props.shop} onItemChange={props.onItemChange} onDeleteItem={props.onDeleteItem} items={props.items} /></div>
            </Col>
            {(props.shop && props.items && props.items.length > 0) && (
                <Col xs={12} lg={5} style={{ borderLeft: `1px solid ${Color.Grey}`, padding: "1rem 2rem 0 2rem" }}>
                    <OrderTypeSelection onActionChange={props.onActionChange} address={props.address} delivery={props.delivery} shop={props.shop} basketType={props.basketType} onBasketTypeChange={props.onBasketTypeChange} onCollectionChange={props.onCollectionChange} />
                    <OrderTotal items={itemTotal} delivery={deliveryTotal} />
                    {(props.delivery?.isIntegrated && props.basketType == BasketType.Delivery) ? (
                        <Card style={{ borderRadius: 0, border: 0, paddingLeft: 0, paddingRight: 0, borderTop: `1px solid ${Color.Grey}` }}>
                            <Card.Body>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <p style={{ marginTop: "1rem" }}>The delivery service is only available through our mobile app.</p>
                                        <p>Through the app you can purchase from multiple local shops with one delivery!</p>
                                    </Col>
                                    <Col xs={12} sm={6} style={{ textAlign: "center" }}>
                                        <a
                                            href='https://play.google.com/store/apps/details?id=uk.co.ecomni.mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img alt="Get it on Google Play" width={"100%"} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' />
                                        </a>
                                        <a href='https://apps.apple.com/us/app/ecomni-market/id1640252469'>
                                            <img alt="Get it on App Store" width={"87%"} src='https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg' />
                                        </a>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    ) : (
                        <Card style={{ borderRadius: 0, border: 0, paddingLeft: 0, paddingRight: 0, borderTop: `1px solid ${Color.Grey}` }}>
                            <Card.Body>
                                <Row>
                                    <Col xs={12}>
                                        {(!isOverMinOrder && props.delivery) && <ErrorView message={`A minimum total purchase amount of £${Number(props.delivery.minimumPrice).toFixed(2)} is required to checkout for delivery`} />}
                                        {(props.error !== "") && <ErrorView message={props.error} />}
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: "1rem" }}>
                                    <Col xs={12} sm={6}>
                                        <Button onClick={() => props.onNavigate((props.shop) ? `${Path.Shop}/${props.shop?.id}` : Path.Dashboard)} name="Back to shop" variant={variant.Secondary} style={{ marginRight: "1rem", width: "100%", marginTop: ".5rem", marginBottom: ".5rem" }} />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <Button disabled={(props.basketType === BasketType.Delivery && !props.shop.isDelivery) || !isOverMinOrder} style={{ width: "100%", marginTop: ".5rem", marginBottom: ".5rem" }} onClick={() => props.onNavigate(Path.Checkout)} name="Proceed to checkout" variant={variant.Primary} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            )}
        </Row >
    )
}

export interface OrderTypeSelectionProps {
    shop?: Shop;
    delivery?: Delivery;
    address?: AddressDto;
    basketType: BasketType;
    onActionChange: (action: Action) => void;
    onBasketTypeChange: (type: BasketType) => void;
    onCollectionChange: (contact: ShopContact) => void;
}

export const OrderTypeSelection: FunctionComponent<OrderTypeSelectionProps> = (props) => (
    <Card style={{ border: 0 }}>
        <Card.Body>
            <ButtonGroup style={{ width: "100%", borderRadius: 0 }}>
                <ToggleButton
                    key={"collection"}
                    type="radio"
                    variant={(props.basketType === BasketType.Collection) ? 'outline-success' : "outline-secondary"}
                    name="radio-collection"
                    value={BasketType.Collection}
                    checked={props.basketType === BasketType.Collection}
                    onChange={() => props.onBasketTypeChange(BasketType.Collection)}
                    onClick={() => props.onBasketTypeChange(BasketType.Collection)}
                    style={{ borderRadius: 0, backgroundColor: (props.basketType === BasketType.Collection) ? getPrimaryColor() : Color.White }}
                >
                    <FaShoppingBag size={30} />
                    <p>Click & Collect</p>
                </ToggleButton>
                <ToggleButton
                    key={"delivery"}
                    type="radio"
                    variant={(props.basketType === BasketType.Delivery) ? 'outline-success' : "outline-secondary"}
                    name="radio-delivery"
                    value={BasketType.Delivery}
                    checked={props.basketType === BasketType.Delivery}
                    onChange={() => props.onBasketTypeChange(BasketType.Delivery)}
                    onClick={() => props.onBasketTypeChange(BasketType.Delivery)}
                    style={{ borderRadius: 0, backgroundColor: (props.basketType === BasketType.Delivery) ? getPrimaryColor() : Color.White }}
                >
                    <FaBicycle size={30} />
                    <p>Delivery</p>
                </ToggleButton>
            </ButtonGroup>
            {(props.basketType === BasketType.Collection) && <ShopCollectionView shop={props.shop!} onCollectionChange={props.onCollectionChange} />}
            {(props.basketType === BasketType.Delivery) && <DeliveryDetails shop={props.shop} onActionChange={props.onActionChange} address={props.address} delivery={props.delivery} />}
        </Card.Body>
    </Card>
)

export interface BasketItemsViewProps {
    items: BasketItem[];
    shop?: Shop;
    onNavigate: (path: string) => void;
    onItemChange: (item: BasketItem) => void
    onDeleteItem: (item: BasketItem) => void
}

export const BasketItemsView: FunctionComponent<BasketItemsViewProps> = (
    props
) => (
    <>
        {(!props.items || props.items.length === 0) ? (
            <Card style={{ borderRadius: 0 }}>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card.Title>No items</Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Text>There are currently no items in your basket.</Card.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => props.onNavigate((props.shop) ? `${Path.Shop}/${props.shop?.id}` : Path.Dashboard)} name="Back to shop" variant={variant.Secondary} style={{ marginRight: "1rem", marginTop: ".5rem", marginBottom: ".5rem" }} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        ) : (
            <Row style={{ textAlign: "center", paddingBottom: "1rem" }}>
                <Col xs={5}>Item</Col>
                <Col xs={3}>Quantity</Col>
                <Col xs={2}>Remove</Col>
                <Col xs={2}>Price</Col>
            </Row>
        )}
        {
            props.items.map(item => (
                <Row>
                    <Card style={{ borderRadius: 0, border: 0, paddingLeft: 0, paddingRight: 0, borderTop: `1px solid ${Color.Grey}` }}>
                        <Card.Body>
                            <Row>
                                <Col xs={5}>
                                    <Row>
                                        <Col xs={5}>
                                            {item.img && (
                                                <Card.Img
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        objectFit: "cover",
                                                    }}
                                                    src={item.img!}
                                                />
                                            )}
                                            {!item.img && (
                                                <Card.Img
                                                    style={{
                                                        width: "80px",
                                                        height: "80px",
                                                        objectFit: "cover",
                                                    }}
                                                    src={process.env.REACT_APP_DEFAULT_IMAGE}
                                                />
                                            )}
                                        </Col>
                                        <Col xs={7}>
                                            <Card.Title style={{ margin: 0, fontSize: "1.15rem" }}>{item.name}</Card.Title>
                                            {item.variants && item.variants.map(item => (
                                                <Card.Text style={{ margin: 0 }}>{item.name}: {item.value}</Card.Text>
                                            ))}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={3} style={{ textAlign: "center" }}>
                                    <ButtonGroup>
                                        <BButton onClick={() => props.onItemChange({
                                            ...item,
                                            volume: (item.volume || 0) - (item.productVolume)
                                        })} style={{ background: getPrimaryColor(), border: 0, borderRadius: 0 }}>-</BButton>
                                        <BButton style={{ background: getPrimaryColor(), border: 0, borderRadius: 0 }} disabled>{item.volume || 0} {(item.uom && item.uom !== "Unit") ? ` ${item.uom}` : ""}</BButton>
                                        <BButton onClick={() => props.onItemChange({
                                            ...item,
                                            volume: (item.volume || 0) + (item.productVolume || 1)
                                        })} style={{ background: getPrimaryColor(), border: 0, borderRadius: 0 }}>+</BButton>
                                    </ButtonGroup>
                                </Col>
                                <Col xs={2} style={{ textAlign: "center" }}>
                                    <Button style={{ color: Color.Red }} variant={variant.Border} color={Color.Red} name="X" onClick={() => props.onDeleteItem(item)} />
                                </Col>
                                <Col xs={2} style={{ textAlign: "center" }}>
                                    <Card.Text>£{Number(item.price).toFixed(2)}</Card.Text>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card >
                </Row>
            ))
        }
    </>
)

export const MobileBasketItemsView: FunctionComponent<BasketItemsViewProps> = (
    props
) => (
    <>
        {(!props.items || props.items.length === 0) && (
            <Card style={{ borderRadius: 0 }}>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card.Title>No items</Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Text>There are currently no items in your basket.</Card.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button onClick={() => props.onNavigate((props.shop) ? `${Path.Shop}/${props.shop?.id}` : Path.Dashboard)} name="Back to shop" variant={variant.Secondary} style={{ marginRight: "1rem", width: "100%", marginTop: ".5rem", marginBottom: ".5rem" }} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )}
        {
            props.items.map(item => (
                <Row>
                    <Card style={{ borderRadius: 0, border: 0, paddingLeft: 0, paddingRight: 0, borderTop: `1px solid ${Color.Grey}` }}>
                        <Card.Body>
                            <Row>
                                <Col xs={7}>
                                    <Row>
                                        <Col xs={5}>
                                            {item.img && (
                                                <Card.Img
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                    }}
                                                    src={item.img!}
                                                />
                                            )}
                                            {!item.img && (
                                                <Card.Img
                                                    style={{
                                                        width: "50px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                    }}
                                                    src={process.env.REACT_APP_DEFAULT_IMAGE}
                                                />
                                            )}
                                        </Col>
                                        <Col>
                                            <div style={{ display: "inline-block", overflowWrap: "break-word" }}>
                                                <Card.Title style={{ margin: 0, fontSize: "1rem" }}>{item.name}</Card.Title>
                                                {item.variants && item.variants.map(item => (
                                                    <Card.Text style={{ margin: 0, fontSize: ".8rem" }}>{item.name}: {item.value}</Card.Text>
                                                ))}
                                                <Card.Text style={{ fontSize: ".9rem", fontWeight: 600 }}>£{Number(item.price).toFixed(2)}</Card.Text>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={3} style={{ textAlign: "center" }}>
                                    <BButton onClick={() => props.onItemChange({
                                        ...item,
                                        volume: (item.volume || 0) + (item.productVolume || 1)
                                    })} style={{ background: getPrimaryColor(), border: 0, borderRadius: 0 }}>+</BButton>
                                    <p style={{ margin: ".2rem" }}>{item.volume || 0} {(item.uom && item.uom !== "Unit") ? ` ${item.uom}` : ""}</p>
                                    <BButton onClick={() => props.onItemChange({
                                        ...item,
                                        volume: (item.volume || 0) - (item.productVolume)
                                    })} style={{ background: getPrimaryColor(), border: 0, borderRadius: 0 }}>-</BButton>
                                </Col>
                                <Col xs={2} style={{ textAlign: "center" }}>
                                    <Button style={{ color: Color.Red }} variant={variant.Border} color={Color.Red} name="X" onClick={() => props.onDeleteItem(item)} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card >
                </Row>
            ))
        }
    </>
)

export interface ShopCollectionViewProps {
    shop: Shop
    onCollectionChange: (contact: ShopContact) => void;
}

export const ShopCollectionView: FunctionComponent<ShopCollectionViewProps> = (
    props
) => (
    <Card style={{ borderRadius: 0, border: 0, paddingLeft: 0, paddingRight: 0, borderTop: `1px solid ${Color.Grey}` }}>
        <Card.Body>
            <Row>
                <Col>
                    <Card.Text style={{ textAlign: "center", marginBottom: ".5rem" }}>Select Collection Address</Card.Text>
                </Col>
            </Row>
            <Row>
                <ListGroup style={{ padding: 0 }}>
                    {props.shop.contacts.map(contact => (
                        <ListGroup.Item onClick={() => props.onCollectionChange(contact)} style={(props.shop.address == contact.address) ? { borderRadius: 0, background: getPrimaryColor(), cursor: "pointer" } : { borderRadius: 0, cursor: "pointer" }} active={props.shop.address == contact.address}>
                            {contact.address}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Row>

        </Card.Body>
    </Card>
)

export interface DeliveryViewProps {
    address?: AddressDto;
    delivery?: Delivery;
    shop?: Shop;
    onActionChange: (action: Action) => void;
}

export const DeliveryDetails: FunctionComponent<DeliveryViewProps> = (
    props
) => (
    <Card style={{ borderRadius: 0, border: 0, paddingLeft: 0, paddingRight: 0, borderTop: `1px solid ${Color.Grey}` }}>
        <Card.Body>
            <Row>
                <Col style={{ paddingBottom: "1rem" }}>
                    {(!props.shop?.isDelivery && props.address && props.address.value != "") && <ErrorView message={`${props.shop?.name} does not deliver to ${props.address?.value}`} />}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card.Text>Address</Card.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    {(props.address && props.address?.value != "") ? (
                        <Card.Text>{props.address?.value} (<a onClick={() => props.onActionChange(Action.ChangeDelivery)} style={{ color: getPrimaryColor(), cursor: "pointer" }}>Change</a>)</Card.Text>
                    ) : (
                        <Card.Text><a onClick={() => props.onActionChange(Action.ChangeDelivery)} style={{ color: getPrimaryColor(), cursor: "pointer" }}>Add your delivery address</a></Card.Text>
                    )}
                </Col>
            </Row>
            <Row style={{ marginTop: ".5rem" }}>
                <Col>
                    <Card.Text>Delivery Fee</Card.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <Card.Text>£{(props.delivery) ? Number(props.delivery.price).toFixed(2) : "0.00"}</Card.Text>
                </Col>
            </Row>
        </Card.Body>
    </Card>
)

export interface OrderTotalProps {
    items: number
    delivery: number
}

export const OrderTotal: FunctionComponent<OrderTotalProps> = (
    props
) => (
    <Card style={{ borderRadius: 0, border: 0, paddingLeft: 0, paddingRight: 0, borderTop: `1px solid ${Color.Grey}` }}>
        <Card.Body>
            <Row>
                <Col>
                    <Card.Text style={{ fontSize: "1.2rem", fontWeight: 600 }}>Total</Card.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <Card.Text style={{ fontSize: "1.2rem", fontWeight: 600, color: getPrimaryColor() }}>£{Number(props.items + props.delivery).toFixed(2)}</Card.Text>
                </Col>
            </Row>
        </Card.Body>
    </Card>
)

export function getItemTotal(items: BasketItem[]): number {
    return items.reduce((a, b) => a + (b.price * 100), 0) / 100
}

