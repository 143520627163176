import { Account, AccountAction, DELETE_ACCOUNT, UPDATE_ACCOUNT } from "../types";

const INITIAL_STATE: Account = {
    data: undefined
};

export const accountReducer = (state = INITIAL_STATE, action: AccountAction) => {
    let { data } = state;
    switch (action.type) {
        case UPDATE_ACCOUNT:
            if (action.payload?.data?.address !== undefined) {
                data = {
                    ...data,
                    address: action.payload?.data?.address
                }
            }
            if (action.payload?.data?.username) {
                data = {
                    ...data,
                    username: action.payload?.data?.username
                }
            }
            if (action.payload?.data?.isUser) {
                data = {
                    ...data,
                    isUser: action.payload?.data?.isUser
                }
            }
            if (action.payload?.data?.collection) {
                data = {
                    ...data,
                    collection: action.payload?.data?.collection
                }
            }
            if (action.payload?.data?.email) {
                data = {
                    ...data,
                    email: action.payload?.data?.email
                }
            }
            if (action.payload?.data?.isMarketing) {
                data = {
                    ...data,
                    isMarketing: action.payload.data.isMarketing
                }
            }
            data = {
                ...data,
                lastUpdated: Date.now()
            }
            return {
                data: data,
            }
        case DELETE_ACCOUNT:
            data = undefined
            return {
                data: data
            };
        default:
            return state;
    }
};