import { Component, FunctionComponent, useRef } from "react";
import { Auth } from "aws-amplify";
import { Category, IDeleteCustomerFavorite, IGetCategories, IGetCustomerFavorites, IGetProducts, IGetShopContactDetails, ISendMessage, ISetCustomerFavorite, Product, ProductVariantOverride, ShopContactDetails } from "../../client/core";
import { RouteComponentProps } from "react-router";
import { Alert, Button, ButtonGroup, Card, Col, Form, Modal, Row, Spinner, Image, Container, Badge, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { ADD_ITEM, AccountAction, Address as AddressDto, BasketAction, BasketItem, BasketVariant, JourneyAction, Shop as ShopDto, UPDATE_JOURNEY } from "../../state/types";
import { Color, Path, Storage, getPrimaryColor } from "../../env";
import { Button as EButton, Icon, icon, variant } from "../form/Button"
import { Dispatch } from "redux";
import { ActiveShopAction, Shop as ShopSummary, ShopsAction, UPDATE_SHOP, UPDATE_SHOPS } from "../../state/types/shop";
import { TbLetterA, TbLetterB, TbLetterC, TbLetterD, TbLetterE, TbLetterF, TbLetterG, TbLetterH, TbLetterI, TbLetterJ, TbLetterK, TbLetterL, TbLetterM, TbLetterN, TbLetterO, TbLetterP, TbLetterQ, TbLetterR, TbLetterS, TbLetterT, TbLetterU, TbLetterV, TbLetterW, TbLetterX, TbLetterY, TbLetterZ } from "react-icons/tb";
import { IconBaseProps } from "react-icons";
import ReactMarkdown from "react-markdown";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const DEFAULT_CATEGORY: string = "Other"

type TParams = { shopId: string };

export type BasketDispatch = Dispatch<BasketAction>;

export type RouteShopProps = RouteComponentProps<TParams>;

export type ActiveShopDispatch = Dispatch<ActiveShopAction>;

export type JourneyDispatch = Dispatch<JourneyAction>;

export type ShopsDispatch = Dispatch<ShopsAction>;

export type AccountDispatch = Dispatch<AccountAction>;

export interface ShopProps extends RouteShopProps {
    auth: typeof Auth;
    getProductsAPI: IGetProducts;
    getCategoriesAPI: IGetCategories;
    getShopContactAPI: IGetShopContactDetails;
    getFavoritesAPI: IGetCustomerFavorites;
    setFavoriteAPI: ISetCustomerFavorite;
    deleteFavoriteAPI: IDeleteCustomerFavorite;
    sendShopMessageAPI: ISendMessage;
    basketDispatch: BasketDispatch;
    activeShopDispatch: ActiveShopDispatch;
    accountDispatch: AccountDispatch;
    journeyDispatch: JourneyDispatch;
    basket: BasketItem[];
    activeShop?: ShopSummary;
    address?: AddressDto;
    shops?: ShopDto[];
}

export interface ShopState {
    action: Action;
    isLoading: boolean;
    error: string;
    activeProduct?: Product;
    currActiveItem?: BasketItem;
    products: Map<string, Product[]>;
    currentProducts: Map<string, Product[]>;
    favorites: Map<string, Product[]>;
    currentFavorites: Map<string, Product[]>;
    activeProductError: string;
    categories: Category[];
    currentCategory?: Category;
    showFavorites: boolean;
    shopMessage: string;
    shopMessageEmail: string;
    shopMessageSent: boolean;
}

export enum Action {
    None,
    ViewShop,
    SelectShop,
    KnockOnShopDoor,
}

export class Shop extends Component<
    ShopProps,
    ShopState
> {
    constructor(props: ShopProps) {
        super(props);
        this.state = {
            shopMessageSent: false,
            shopMessageEmail: "",
            action: Action.ViewShop,
            isLoading: true,
            error: "",
            products: new Map(),
            currentProducts: new Map(),
            favorites: new Map(),
            currentFavorites: new Map(),
            activeProductError: "",
            categories: [],
            showFavorites: false,
            shopMessage: ""
        };
    }

    componentDidMount() {
        this.getShopSummary().then(async shopFound => {
            if (!shopFound) {
                return
            }

            await this.getProducts()

            const queryParams = new URLSearchParams(this.props.history.location.search)
            const category = queryParams.get('category')
            if (category) {
                return this.handleSelectCategory({ name: category })
            }

            if (localStorage.getItem(Storage.IsLoggedIn) == "true") {
                this.getCustomerFavorites()
            }
        }).catch((err: Error) => {
            this.setState({
                error: err.message
            })
        })
    }

    getCustomerFavorites = () => {
        this.props.auth.currentSession().then(session => {
            this.props.getFavoritesAPI.GetCustomerFavorites({
                identityToken: session.getIdToken().getJwtToken()
            }).then(response => {
                if (response.statusCode === 403) {
                    this.props.auth.signOut()
                    localStorage.removeItem(Storage.IsLoggedIn)
                    return
                }

                if (response.statusCode !== 200) {
                    return
                }

                const favorites = this.sortProductsByCategory(response.body?.items)

                this.setState({
                    favorites: favorites,
                    currentFavorites: favorites,
                    showFavorites: (response.body?.items && response.body.items.length > 0) || false,
                })
            }).catch(err => {
                return
            })
        }).catch(err => {
            this.props.auth.signOut()
            localStorage.removeItem(Storage.IsLoggedIn)
        })
    }

    getProducts = (shopID?: string) => {
        return new Promise<void>(async (resolve) => {
            try {
                const response = await this.props.getProductsAPI
                    .GetProducts({
                        shopID: shopID || this.props.activeShop!.id,
                    })

                if (response.statusCode === 403) {
                    localStorage.removeItem(Storage.IsLoggedIn)
                    this.props.auth.signOut();
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Could not retrieve shop products at this time. Please try again later.",
                    });
                }

                const products: Map<string, Product[]> = this.sortProductsByCategory(response.body)
                const categories: Category[] = Array.from(products.keys()).map(category => {
                    return {
                        name: category
                    }
                })

                this.setState({
                    products: products,
                    currentProducts: products,
                    categories: categories,
                }, () => resolve())
            } catch {
                this.setState({
                    error: "Could not retrieve shop products at this time. Please try again later.",
                }, () => resolve());
            } finally {
                this.setState({
                    isLoading: false,
                }, () => resolve());
            }
        })
    }

    sortProductsByCategory = (products: Product[] | undefined): Map<string, Product[]> => {
        if (!products) {
            return new Map()
        }
        const response: Map<string, Product[]> = new Map()
        products.forEach((product) => {
            let category = product.category || DEFAULT_CATEGORY
            if (!response.has(category)) {
                response.set(category, [])
            }
            const catProducts = response.get(category)
            catProducts!.push(product)
            response.set(category, catProducts!)
        })
        return new Map(Array.from(response.entries()).sort())
    }

    getShopSummary = async (): Promise<boolean> => {
        const response = await this.props.getShopContactAPI.GetShopContactDetails({
            shopId: this.props.match.params.shopId,
            address: {
                address: this.props.address?.value!,
                id: this.props.address?.metadata.id!
            }
        })

        if (response instanceof Error) {
            throw new Error("Could not retrieve shop information at this time.")
        }

        if (response.statusCode !== 200 || !response.body) {
            throw new Error("Could not retrieve shop information at this time.")
        }

        this.props.activeShopDispatch({
            type: "UPDATE_SHOP",
            payload: {
                address: response.body.address,
                addressId: response.body.addressId,
                id: response.body.id,
                name: response.body.name,
                email: response.body.email,
                phone: response.body.phone,
                logo: response.body.logo,
                isCollection: response.body.isCollection,
                isDelivery: response.body.isDelivery,
                paymentInstructions: response.body.paymentInstructions,
                paymentForm: response.body.paymentForm,
                area: response.body.area,
                color: response.body.color,
                contacts: response.body.contacts,
                active: response.body.active,
            }
        })

        this.props.journeyDispatch({
            type: UPDATE_JOURNEY,
            payload: {
                base: `${Path.Shop}/${response.body?.id}`,
            }
        })

        return true
    }

    handleProductSearch = (input: string): void => {
        const filteredProducts: Product[] = Array.from(this.state.products.values()).
            flat().
            filter(p => p.name.toLowerCase().includes(input.toLowerCase()))

        this.setState({
            currentProducts: this.sortProductsByCategory(filteredProducts)
        })
    }

    componentDidUpdate(prevProps: ShopProps) {
        if (prevProps.address?.metadata.id !== this.props.address?.metadata.id) {
            this.getShopSummary()
        }
    }

    handleProductSelect = (product?: Product): void => {
        if (!product) {
            return this.setState({
                activeProductError: "",
                activeProduct: product,
                currActiveItem: undefined,
            })
        }

        const items = this.props.basket.filter(item => item.id === product.id)

        if (items.length === 0) {
            return this.setState({
                activeProduct: product,
                currActiveItem: {
                    id: product.id!,
                    name: product.name,
                    price: product.price!,
                    shopId: this.props.activeShop!.id,
                    img: product.image,
                    volume: 0,
                    uom: product.uom,
                    productPrice: product.price!,
                    productVolume: product.volume || 1,
                },
            })
        }

        const item: BasketItem = JSON.parse(JSON.stringify(items[0]))

        return this.setState({
            activeProduct: product,
            currActiveItem: item,
        })
    }

    handleProductChange = (item: BasketItem): void => {
        this.setState({
            currActiveItem: item,
        })
    }

    handleProductSubmit = (product: Product, item: BasketItem): void => {
        if (product.variants && (!item.variants || product.variants.length !== item.variants.length)) {
            return this.setState({
                activeProductError: "Please select all options before adding item to basket."
            })
        }
        const variantOverride = getVariantOverride(getVariantKey(item.variants), product.variantOverrides)
        const productPrice = (variantOverride && variantOverride.price) ? variantOverride.price : product.price!
        const price = (item.volume) ? (Math.round((item.volume / (product.volume || 1))) * (productPrice! * 100)) / 100 : 0
        this.props.basketDispatch({
            type: ADD_ITEM,
            payload: {
                id: item.id!,
                name: product.name,
                price: price,
                shopId: this.props.activeShop!.id,
                uom: product.uom,
                volume: item.volume || 0,
                img: product.image,
                variants: item.variants,
                productPrice: productPrice,
                productVolume: product.volume || 1
            }
        })
        this.setState({
            activeProduct: undefined,
            activeProductError: "",
        })
    }

    handleSelectCategory = (category?: Category): void => {
        if (!category) {
            this.setState({
                currentProducts: this.state.products,
                currentFavorites: this.state.favorites,
                currentCategory: category,
            })
        }
        const products: Product[] = this.state.products.get(category!.name) || []
        const favorites: Product[] = this.state.favorites.get(category!.name) || []
        const selectedProducts: Map<string, Product[]> = new Map([[category!.name, products]])
        const selectedFavorites: Map<string, Product[]> = new Map([[category!.name, favorites]])
        this.setState({
            currentProducts: selectedProducts,
            currentFavorites: selectedFavorites,
            currentCategory: category,
            showFavorites: (favorites.length > 0)
        })
    }

    handleShopSelect = (shop: ShopDto): void => {
        this.props.activeShopDispatch({
            type: UPDATE_SHOP,
            payload: shop
        })

        this.setState({
            isLoading: true,
            action: Action.ViewShop
        })

        this.getProducts(shop.id)
    }

    handleAddProductToFavorites = (product: Product): void => {
        const productCategory = product.category || DEFAULT_CATEGORY
        if (this.isProductPresent(product, this.state.favorites.get(productCategory) || [])) {
            return this.setState({
                activeProduct: undefined
            })
        }

        product.price = undefined
        product.uom = undefined
        product.volume = undefined

        const { favorites, currentFavorites } = this.state

        if (!favorites.get(productCategory)) {
            const products = [product]
            favorites.set(productCategory, products)
            currentFavorites.set(productCategory, products)
        } else {
            favorites.get(productCategory)!.push(product)
        }


        this.props.auth.currentSession().then(session => {
            this.props.setFavoriteAPI.SetCustomerFavorite({
                identityToken: session.getIdToken().getJwtToken(),
                item: product,
                itemId: product.id!,
                type: "products"
            }).then(response => {
                if (response.statusCode === 403) {
                    this.props.auth.signOut()
                    localStorage.removeItem(Storage.IsLoggedIn)
                    return
                }

                if (response.statusCode !== 200) {
                    const revertedFavorites = favorites.get(productCategory)!.filter(p => product.id != p.id)
                    favorites.set(productCategory, revertedFavorites)
                    return this.setState({
                        favorites: favorites,
                    })
                }

                this.setState({
                    showFavorites: true,
                    activeProduct: undefined,
                })
            }).catch(err => {
                const revertedFavorites = favorites.get(productCategory)!.filter(p => product.id != p.id)
                favorites.set(productCategory, revertedFavorites)
                return this.setState({
                    favorites: favorites
                })
            })
        })
    }


    handleRemoveProductFromFavorites = (product: Product): void => {
        this.props.auth.currentSession().then(session => {
            this.props.deleteFavoriteAPI.DeleteCustomerFavorite({
                identityToken: session.getIdToken().getJwtToken(),
                itemId: product.id!,
            }).then(response => {
                if (response.statusCode !== 200) {
                    return this.setState({
                        activeProductError: "This product cannot be removed from your favorites at this moment. If this problem persists please contact support@stumbled.online"
                    })
                }

                const { favorites } = this.state
                let items = favorites.get(product.category || DEFAULT_CATEGORY)
                items = items?.filter(p => p.id !== product.id)

                if (!items || items.length === 0) {
                    favorites.delete(product.category || DEFAULT_CATEGORY)
                    return this.setState({
                        favorites: favorites,
                        activeProduct: undefined,
                    })
                }

                favorites.set(product.category || DEFAULT_CATEGORY, items)
                return this.setState({
                    favorites: favorites,
                    activeProduct: undefined,
                })
            }).catch(err => {
                return this.setState({
                    activeProductError: "This product cannot be removed from your favorites at this moment. If this problem persists please contact support@stumbled.online"
                })
            })
        })
    }

    isProductPresent = (product: Product, products: Product[]): boolean => {
        return products.find(p => p.id === product.id) !== undefined
    }

    sendShopMessage = async (type: string): Promise<void> => {
        if (!this.props.activeShop) {
            return
        }

        if (this.state.shopMessageEmail === "") {
            return this.setState({
                error: "Please enter an email address"
            })
        }

        try {
            const response = await this.props.sendShopMessageAPI.SendMessage({
                type: type,
                email: this.state.shopMessageEmail,
                message: this.state.shopMessage,
                shopId: this.props.activeShop.id,
            })

            console.log(response)

            if (response.statusCode !== 200) {
                return this.setState({
                    error: "We were unable to send a message to the shop at this time"
                })
            }

            this.setState({
                shopMessageSent: true
            })
        } catch {
            return this.setState({
                error: "We were unable to send a message to the shop at this time"
            })
        }
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Row style={{ textAlign: "center" }}>
                    <Spinner style={{ margin: "auto", color: getPrimaryColor() }} animation="border" />
                </Row>
            )
        }

        if (this.state.action === Action.SelectShop) {
            const selectShopProps: SelectShopProps = {
                shops: this.props.shops || [],
                onSelect: this.handleShopSelect,
            }
            return (
                <SelectShop {...selectShopProps} />
            )
        }

        if (!this.props.activeShop?.active) {
            return <ClosedShopView
                error={this.state.error}
                onCustomerEmailChange={email => { this.setState({ ...this.state, shopMessageEmail: email }) }}
                messageSent={this.state.shopMessageSent}
                shop={this.props.activeShop}
                onShopMessageSubmit={this.sendShopMessage}
                onShopMessageChange={(message) => this.setState({ shopMessage: message })}
                action={this.state.action}
                onActionChange={action => this.setState({
                    ...this.state,
                    action: action,
                })}
            />
        }

        const listProductsViewProps: ShopViewProps = {
            error: this.state.error,
            products: this.state.currentProducts,
            favorites: this.state.currentFavorites,
            activeProduct: this.state.activeProduct,
            currActiveItem: this.state.currActiveItem,
            activeProductError: this.state.activeProductError,
            categories: this.state.categories,
            currentCategory: this.state.currentCategory,
            shop: this.props.activeShop,
            showFavorites: this.state.showFavorites,
            addProductToFavorites: (localStorage.getItem(Storage.IsLoggedIn) == "true") ? this.handleAddProductToFavorites : undefined,
            removeProductFromFavorites: (localStorage.getItem(Storage.IsLoggedIn) == "true") ? this.handleRemoveProductFromFavorites : undefined,
            onProductSubmit: this.handleProductSubmit,
            onProductSelect: this.handleProductSelect,
            onProductChange: this.handleProductChange,
            onProductSearch: this.handleProductSearch,
            onCategorySelect: this.handleSelectCategory,
        };
        return (
            <ShopView {...listProductsViewProps} />
        );
    }
}

const mapState = (state: RootState) => ({
    basket: state.basket.items,
    activeShop: state.shop.shop,
    address: state.account.data?.address,
});

const connector = connect(mapState, {});

export default connector(Shop);

export interface SelectShopProps {
    shops: ShopDto[]
    onSelect: (shop: ShopDto) => void
}

export const SelectShop: FunctionComponent<SelectShopProps> = (
    props
) => (
    <div style={{ width: "100vw", height: "100vh", position: "absolute", zIndex: "99999", top: 0, left: 0, background: Color.White }}>
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}>
            <div style={{ width: "80%", maxWidth: "1000px", position: "relative", margin: "auto" }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col>
                            <h1 style={{ textAlign: "center", fontSize: "3rem", fontWeight: 900, color: getPrimaryColor(), marginBottom: "5rem" }}>Which location?</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        {props.shops.map(shop => (
                            <Col xs={6} md={3} style={{ padding: "1rem" }}>
                                <a style={{ cursor: "pointer" }} onClick={() => props.onSelect(shop)}>
                                    <Image width={"100%"} src={(shop.logo && shop.logo.trim() !== "") ? shop.logo : `${process.env.REACT_APP_DEFAULT_IMAGE}${(shop.area) ? shop.area.charAt(0) : "O"}`} rounded />
                                    <p style={{ textAlign: "center", marginTop: ".75rem", fontWeight: 600, fontSize: "1.1rem", color: "#5C5C5C" }}>{(shop.area) ? shop.area : "Other"}</p>
                                </a>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    </div>
);

export interface ShopViewProps {
    error: string;
    products: Map<string, Product[]>;
    favorites: Map<string, Product[]>;
    showFavorites: boolean;
    currActiveItem?: BasketItem;
    activeProduct?: Product;
    activeProductError: string;
    categories: Category[];
    currentCategory?: Category;
    shop?: ShopSummary;
    onProductSelect: (product?: Product) => void;
    onProductSubmit: (product: Product, item: BasketItem) => void;
    onProductChange: (item: BasketItem) => void;
    onProductSearch: (input: string) => void;
    onCategorySelect: (category?: Category) => void;
    addProductToFavorites?: (product: Product) => void;
    removeProductFromFavorites?: (product: Product) => void;
}

export const ShopView: FunctionComponent<ShopViewProps> = (
    props
) => (
    <>
        <ProductSearch onChange={props.onProductSearch} />
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <Row>
            {(props.categories && props.categories.length > 0) && <CategoryMenu currentCategory={props.currentCategory} categories={props.categories} onSelect={props.onCategorySelect} />}
        </Row>
        <Row>
            <Col>
                <ProductsView
                    showFavorites={props.showFavorites}
                    activeProduct={props.activeProduct}
                    activeProductError={props.activeProductError}
                    products={props.products}
                    favorites={props.favorites}
                    currActiveItem={props.currActiveItem}
                    onSubmit={props.onProductSubmit}
                    onProductChange={props.onProductChange}
                    onSelect={props.onProductSelect}
                    addProductToFavorites={props.addProductToFavorites}
                    removeProductFromFavorites={props.removeProductFromFavorites}
                />
            </Col>
        </Row>
    </>
);

export interface ProductSearchProps {
    onChange: (input: string) => void
}

export const ProductSearch: FunctionComponent<ProductSearchProps> = (
    props
) => (
    <>
        <MobileSearch {...props} />
        <DesktopSearch {...props} />
    </>
)

export const MobileSearch: FunctionComponent<ProductSearchProps> = (
    props
) => (
    <Row className="d-md-none">
        <Col>
            <div className="input-group" style={{ width: "100%", margin: "auto" }}>
                <div className="form-outline" style={{ marginBottom: "2rem", width: "100%", display: "flex" }}>
                    <div className="form-outline flex-grow-1" >
                        <input style={{ borderRadius: 0 }} onChange={e => props.onChange(e.target.value)} type="search" id="product-search-bar" className="form-control" placeholder="Search for a product" />
                    </div>
                    <EButton onClick={() => { }} variant={variant.Primary} icon={icon.Search} />
                </div>
            </div>
        </Col>
    </Row>
)

export const DesktopSearch: FunctionComponent<ProductSearchProps> = (
    props
) => (
    <Row className="d-none d-md-block" style={{ position: "fixed", top: 0, zIndex: 9, right: 0, left: 0, width: "50%", margin: "auto", maxWidth: "750px" }}>
        <Col>
            <div className="input-group" style={{ marginTop: "1rem", width: "100%", margin: "auto" }}>
                <div className="form-outline" style={{ marginTop: "1rem", width: "100%", display: "flex" }}>
                    <div className="form-outline flex-grow-1" >
                        <input style={{ borderRadius: 0 }} onChange={e => props.onChange(e.target.value)} type="search" id="product-search-bar" className="form-control" placeholder="Search for a product" />
                    </div>
                    <EButton onClick={() => { }} variant={variant.Primary} icon={icon.Search} />
                </div>
            </div>
        </Col>
    </Row>
)

export interface ShopDetailsViewProps {
    shop: ShopSummary;
}

export const ShopDetailsView: FunctionComponent<ShopDetailsViewProps> = (
    props
) => (
    <Row>
        <Col>
            <Card>
                <Card.Body>
                    <Card.Title>{props.shop.name}</Card.Title>
                    <Card.Text>{props.shop.address}</Card.Text>
                </Card.Body>
            </Card>
        </Col>
    </Row>
)

function isProductFavorite(favorites: Map<string, Product[]>, product: Product): boolean {
    for (let category of Array.from(favorites.keys())) {
        if (favorites.get(category)!.find(p => p.id == product.id) !== undefined) {
            return true
        }
    }

    return false
}

export interface ProductsViewProps {
    activeProduct?: Product
    currActiveItem: BasketItem | undefined
    products: Map<string, Product[]>;
    showFavorites: boolean;
    favorites: Map<string, Product[]>;
    activeProductError: string;
    onSelect: (product?: Product) => void;
    onProductChange: (item: BasketItem) => void;
    onSubmit: (product: Product, item: BasketItem) => void;
    addProductToFavorites?: (product: Product) => void;
    removeProductFromFavorites?: (product: Product) => void;
}

export const ProductsView: FunctionComponent<ProductsViewProps> = (
    props
) => (
    <>
        <Modal show={props.activeProduct !== undefined} size={"xl"} onHide={() => props.onSelect(undefined)}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {(props.currActiveItem && props.activeProduct) && <ProductView
                    isFavorite={isProductFavorite(props.favorites, props.activeProduct)}
                    error={props.activeProductError}
                    onSubmit={props.onSubmit}
                    item={props.currActiveItem}
                    product={props.activeProduct}
                    onItemChange={props.onProductChange}
                    addProductToFavorites={props.addProductToFavorites}
                    removeProductFromFavorites={props.removeProductFromFavorites}
                />}
            </Modal.Body>
        </Modal>
        {(props.showFavorites) && <Row>
            <h6 style={{ textAlign: "center", marginTop: "2rem", color: Color.DarkGrey }}>My Favourites</h6>
            {Array.from(props.favorites.keys()).map(category => (
                <>
                    {props.favorites.get(category)!.map(product => (
                        <ProductCard key={product.id} product={product} {...props} onSelect={product => props.onSelect(props.products.get(category)!.find(p => p.id == product?.id))} />
                    ))}
                </>
            ))}
        </Row>}
        <Row>
            {Array.from(props.products.keys()).map(category => (
                <>
                    <h6 style={{ textAlign: "center", marginTop: "2rem", color: Color.DarkGrey }}>{category}</h6>
                    {props.products.get(category)!.map(product => (
                        <ProductCard key={product.id} product={product} {...props} />
                    ))}
                </>
            ))}
        </Row>
    </>
)

export interface CategoryMenuProps {
    currentCategory?: Category
    categories: Category[]
    onSelect: (category?: Category) => void;
}

export const CategoryMenu: FunctionComponent<CategoryMenuProps> = (
    props
) => {
    const menuRef = useRef<HTMLDivElement>(null);

    // Scroll function for left and right buttons
    const scroll = (scrollOffset: number): void => {
        if (menuRef.current == null) {
            return
        }
        menuRef.current.scrollBy({
            left: scrollOffset,
            behavior: 'smooth'
        });
    };

    return (
        <div style={{ justifyContent: "space-between", display: "flex", width: "100%" }}>
            <div className="d-none d-md-block" style={{ display: "inline-block", padding: "1rem" }}>
                <Button variant="outline-secondary" onClick={() => scroll(-200)}><FaArrowLeft /></Button>
            </div>
            <div className="scrolling" style={{ overflowX: "auto", display: "flex", padding: ".5rem" }} ref={menuRef}>
                <a style={{ cursor: "pointer" }} onClick={() => props.onSelect()}>
                    <div style={{ display: "inline-block" }}>
                        <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
                            <span style={{ background: getPrimaryColor(), padding: "0.5rem", borderRadius: "5px" }}>{Letter("a")}</span>
                        </div>
                        <p style={{ color: Color.DarkGrey, fontSize: ".9rem", margin: "0 1rem 0 1rem", fontWeight: (!props.currentCategory) ? 700 : 300, borderBottom: (!props.currentCategory) ? `.3rem solid ${getPrimaryColor()}` : "none" }}>{"All"}</p>
                    </div>
                </a>
                {props.categories.map(category => (
                    <CategoryButton active={props.currentCategory?.name === category.name} category={category} onSelect={props.onSelect} />
                ))}
            </div >
            <div className="d-none d-md-block" style={{ display: "inline-block", padding: "1rem" }}>
                <Button variant="outline-secondary" onClick={() => scroll(200)}><FaArrowRight /></Button>
            </div>
        </div>
    )
}

export interface CategoryButtonProps {
    active: boolean
    category: Category
    onSelect: (category?: Category) => void;
}

export const CategoryButton: FunctionComponent<CategoryButtonProps> = (
    props
) => {
    if (props.category.name.startsWith("*")) {
        return (
            <a style={{ cursor: "pointer", marginRight: ".2rem" }} onClick={() => props.onSelect(props.category)}>
                <div style={{ display: "inline-block" }}>
                    <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
                        <span style={{ background: getPrimaryColor(), padding: "0.5rem", borderRadius: "5px" }}>{Letter(props.category.name.replaceAll("*", ""))}</span>
                    </div>
                    <p style={{ color: Color.DarkGrey, fontSize: ".9rem", margin: "0 1rem 0 1rem", fontWeight: 700, borderBottom: (props.active) ? `.3rem solid ${getPrimaryColor()}` : "none" }}>{props.category.name.replaceAll("*", "")}</p>
                </div>
            </a>
        )
    }
    return (
        <a style={{ cursor: "pointer", marginRight: ".2rem" }} onClick={() => props.onSelect(props.category)}>
            <div style={{ display: "inline-block" }}>
                <div style={{ textAlign: "center", marginBottom: ".5rem" }}>
                    <span style={{ background: getPrimaryColor(), padding: "0.5rem", borderRadius: "5px" }}>{Letter(props.category.name)}</span>
                </div>
                <p style={{ color: Color.DarkGrey, fontSize: ".9rem", margin: "0 1rem 0 1rem", fontWeight: (props.active) ? 700 : 300, borderBottom: (props.active) ? `.3rem solid ${getPrimaryColor()}` : "none" }}>{props.category.name}</p>
            </div>
        </a>
    )
}

export interface ProductCardProps {
    product: Product
    onSelect: (product?: Product) => void;
}

export const ProductCard: FunctionComponent<ProductCardProps> = (
    props
) => (
    <Col
        style={{ marginTop: "1rem" }}
        key={props.product.id!}
        xs={12}
        md={4}
        lg={3}
        xxl={2}
    >
        <a
            key={props.product.id!}
            id={"product-" + props.product.id!}
            onClick={() => {
                if (props.product.disabled) {
                    return
                }
                props.onSelect(props.product)
            }}
            style={{ cursor: (props.product.disabled) ? "unset" : "pointer" }}
        >
            <Card style={{ border: 0 }}>
                <div className={"elevate"} style={{ position: "relative", borderRadius: "5px" }}>
                    {props.product.image && (
                        <Card.Img
                            style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                            }}
                            src={props.product.image!}
                        />
                    )}
                    {!props.product.image && (
                        <Card.Img
                            style={{
                                width: "100%",
                                height: "200px",
                                objectFit: "cover",
                            }}
                            src={`${process.env.REACT_APP_DEFAULT_IMAGE}${props.product.name.charAt(0).toUpperCase()}`}
                        />
                    )}
                    {props.product.disabled && <Card.Text style={{ position: "absolute", bottom: 0, padding: ".5rem", width: "100%", textAlign: "center", fontWeight: 600, color: Color.White, background: Color.Red }}>Currently unavailable</Card.Text>}
                </div>
                <Card.Body>
                    <Card.Title>{props.product.name}</Card.Title>
                    {props.product.disabled && <Card.Text>Currently unavailable</Card.Text>}
                    {props.product.price && (
                        <>
                            {(!props.product.category || !props.product.category.toLowerCase().includes("offers")) && <Card.Text>£{props.product.price!.toFixed(2)} {(!props.product.uom || props.product.uom === "Unit") ? "" : "per " + (((props.product.volume && props.product.volume > 1) ? `${props.product.volume} ` : "") + props.product.uom)}</Card.Text>}
                            {(props.product.category && props.product.category.toLowerCase().includes("offers")) && <Card.Text> <Badge bg="danger">On Sale</Badge> <span style={{ color: Color.Red, fontWeight: "700" }}>£{props.product.price!.toFixed(2)} {(!props.product.uom || props.product.uom === "Unit") ? "" : "per " + (((props.product.volume && props.product.volume > 1) ? `${props.product.volume} ` : "") + props.product.uom)}</span></Card.Text>}
                        </>
                    )}
                </Card.Body>
            </Card>
        </a>
    </Col>
)

export interface ProductViewProps {
    product: Product
    item: BasketItem
    error: string
    isFavorite: boolean
    onItemChange: (item: BasketItem) => void
    onSubmit: (product: Product, item: BasketItem) => void;
    addProductToFavorites?: (product: Product) => void;
    removeProductFromFavorites?: (product: Product) => void;
}

export const ProductView: FunctionComponent<ProductViewProps> = (
    props
) => {
    const variantOverride = getVariantOverride(getVariantKey(props.item.variants), props.product.variantOverrides)
    return (
        <div>
            {props.product && (
                <>
                    <Row>
                        <Col xs={12} lg={6} style={{ marginBottom: "1rem" }}>
                            {props.product.image && (
                                <div style={{ width: "100%", paddingBottom: "100%", position: "relative", borderRadius: "5px", overflow: "hidden" }}>
                                    <Card.Img
                                        style={{
                                            width: "100%",
                                            position: "absolute",
                                            borderRadius: "5px",
                                        }}
                                        src={props.product.image}
                                    />
                                </div>
                            )}
                            {!props.product.image && (
                                <div style={{ width: "100%", paddingBottom: "100%", position: "relative", borderRadius: "5px", overflow: "hidden" }}>
                                    <Card.Img
                                        style={{
                                            width: "100%",
                                            position: "absolute",
                                            borderRadius: "5px",
                                        }}
                                        src={`${process.env.REACT_APP_DEFAULT_IMAGE}${props.product.name.charAt(0).toUpperCase()}`}
                                    />
                                </div>
                            )}
                        </Col>
                        <Col xs={12} lg={6} style={{ height: "100%" }}>
                            <Card.Title>{props.product.name}</Card.Title>
                            <Card.Text>£{(variantOverride && variantOverride.price) ? Number(variantOverride.price).toFixed(2) : Number(props.product.price).toFixed(2)} {(!props.product.uom || props.product.uom === "Unit") ? "" : "per " + (((props.product.volume && props.product.volume > 1) ? `${props.product.volume} ` : "") + props.product.uom)}</Card.Text>
                            {props.product.details && props.product.details.map(detail => (
                                <>
                                    <Card.Text style={{ fontWeight: 700, margin: 0 }}>{detail.name}</Card.Text>
                                    <ReactMarkdown>
                                        {detail.description}
                                    </ReactMarkdown>
                                </>
                            ))}
                            <Alert variant={"danger"} show={props.error != ""}>
                                {props.error}
                            </Alert>
                            <div style={{ textAlign: "center" }}>
                                {(props.product.variants) && <Row>
                                    {
                                        props.product.variants.map(variant => (
                                            <Col xs={12} style={{ marginTop: "1rem" }}>
                                                <Form.Select value={props.item.variants?.find(v => variant.name == v.name)?.value || ""} onChange={(e) => props.onItemChange({
                                                    ...props.item,
                                                    variants: changeItemVariant({
                                                        name: variant.name,
                                                        value: e.target.value,
                                                    }, props.item.variants || [])
                                                })}>
                                                    <option value={""} hidden>{`Select ${variant.name}`}</option>
                                                    {variant.options.map(option => (
                                                        <option value={option}>{option}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        ))
                                    }
                                </Row>}
                                <Row>
                                    <Col xs={12} sm={6} style={{ marginTop: ".5rem" }}>
                                        <ButtonGroup style={{ width: "100%" }}>
                                            <Button onClick={() => props.onItemChange({
                                                ...props.item,
                                                volume: (props.item.volume || 0) - (props.product.volume || 1)
                                            })} style={{ background: getPrimaryColor(), border: 0, borderRadius: 0 }}>-</Button>
                                            <Button style={{ background: getPrimaryColor(), border: 0, borderRadius: 0 }} disabled>{props.item.volume || 0} {(props.product.uom && props.product.uom !== "Unit") ? ` ${props.product.uom}` : ""}</Button>
                                            <Button onClick={() => props.onItemChange({
                                                ...props.item,
                                                volume: (props.item.volume || 0) + (props.product.volume || 1)
                                            })} style={{ background: getPrimaryColor(), border: 0, borderRadius: 0 }}>+</Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col xs={12} sm={6} style={{ marginTop: ".5rem" }}>
                                        <EButton style={{ width: "100%" }} icon={icon.Basket} name={"Add to basket"} onClick={() => props.onSubmit(props.product, props.item)} variant={variant.Primary} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6} style={{ marginTop: ".5rem" }}></Col>
                                    <Col xs={12} sm={6} style={{ marginTop: ".5rem" }}>
                                        {(!props.isFavorite && props.addProductToFavorites) && <EButton style={{ width: "100%" }} name={"Add to favourites"} icon={icon.Heart} onClick={() => props.addProductToFavorites!(props.product)} variant={variant.Danger} />}
                                        {(props.isFavorite && props.removeProductFromFavorites) && <EButton style={{ width: "100%" }} name={"Remove from favourites"} icon={icon.Heart} onClick={() => props.removeProductFromFavorites!(props.product)} variant={variant.Danger} />}
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
}

export function getVariantKey(variants?: BasketVariant[] | undefined): string {
    if (!variants) {
        return ""
    }
    let response: string = ""
    for (const variant of variants) {
        response += `${variant.value}#`
    }
    return response.slice(0, -1)
}

export function changeItemVariant(variant: BasketVariant, variants: BasketVariant[]): BasketVariant[] {
    const index = variants.findIndex(v => v.name === variant.name)
    if (index === -1) {
        variants.push(variant)
        return variants
    }
    variants.splice(index, 1, variant)
    return variants
}

export function getVariantOverride(key: string, overrides?: Map<string, ProductVariantOverride>): ProductVariantOverride | undefined {
    if (!overrides) {
        return
    }

    if (!(overrides instanceof Map)) {
        overrides = new Map(Object.entries(overrides))
    }

    if (overrides.has(key)) {
        return overrides.get(key)
    }

    const values: string[] = key.split("#")

    const result = Array.from(overrides.values()).filter(value => {
        let isPresent = true
        if (!value.key || !arraysEqual(value.key.split("#"), values)) {
            isPresent = false
        }
        return isPresent
    })

    if (result.length === 0) {
        return
    }

    return result[0]
}

function arraysEqual(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    return arr1.every((elem) => arr2.includes(elem));
}

const Letter = (input: string) => {
    const fragments = Array.from(input)
    if (fragments.length === 0) {
        return
    }
    const props: IconBaseProps = {
        size: 20,
        color: Color.White
    }
    const icons = new Map([
        ["a", <TbLetterA {...props} />],
        ["b", <TbLetterB {...props} />],
        ["c", <TbLetterC {...props} />],
        ["d", <TbLetterD {...props} />],
        ["e", <TbLetterE {...props} />],
        ["f", <TbLetterF {...props} />],
        ["g", <TbLetterG {...props} />],
        ["h", <TbLetterH {...props} />],
        ["i", <TbLetterI {...props} />],
        ["j", <TbLetterJ {...props} />],
        ["k", <TbLetterK {...props} />],
        ["l", <TbLetterL {...props} />],
        ["m", <TbLetterM {...props} />],
        ["n", <TbLetterN {...props} />],
        ["o", <TbLetterO {...props} />],
        ["p", <TbLetterP {...props} />],
        ["q", <TbLetterQ {...props} />],
        ["r", <TbLetterR {...props} />],
        ["s", <TbLetterS {...props} />],
        ["t", <TbLetterT {...props} />],
        ["u", <TbLetterU {...props} />],
        ["v", <TbLetterV {...props} />],
        ["w", <TbLetterW {...props} />],
        ["x", <TbLetterX {...props} />],
        ["y", <TbLetterY {...props} />],
        ["z", <TbLetterZ {...props} />],
    ])
    return icons.get(fragments[0].toLowerCase())
}

export interface ClosedShopViewProps {
    error: string;
    action: Action;
    shop?: ShopSummary;
    messageSent: boolean;
    onActionChange: (action: Action) => void;
    onShopMessageChange: (message: string) => void;
    onCustomerEmailChange: (email: string) => void;
    onShopMessageSubmit: (type: string) => void;
}

export const ClosedShopView: FunctionComponent<ClosedShopViewProps> = (
    props
) => (
    <Container fluid>
        <Modal
            show={props.action == Action.KnockOnShopDoor}
            onHide={() => props.onActionChange(Action.None)}
        >
            <Modal.Header>
                <Modal.Title>Knock on the door</Modal.Title>
                <EButton icon={icon.Close} onClick={() => props.onActionChange(Action.None)} variant={variant.Secondary} />
            </Modal.Header>
            <Modal.Body>
                {(props.messageSent) ?
                    (
                        <>
                            <Card.Text>Message sent successful. Your message will play a part in building your local community :)</Card.Text>
                            <EButton style={{ width: "100%", marginTop: "1rem" }} name="Close" onClick={() => { props.onActionChange(Action.None) }} variant={variant.Primary} />
                        </>
                    ) : (
                        <>
                            <Alert variant={"danger"} show={props.error != ""}>{props.error}</Alert>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" onChange={(e) => props.onCustomerEmailChange(e.target.value)} placeholder="name@example.com" />
                            </Form.Group>
                            <Card.Text>Would you like to send an optional message?</Card.Text>
                            <InputGroup>
                                <InputGroup.Text>Message</InputGroup.Text>
                                <Form.Control onChange={e => props.onShopMessageChange(e.target.value)} as="textarea" aria-label="Message" rows={8} />
                            </InputGroup>
                            <EButton style={{ width: "100%", marginTop: "1rem" }} name="Submit" onClick={() => { props.onShopMessageSubmit("OpenShopRequest") }} variant={variant.Primary} />
                        </>
                    )
                }
            </Modal.Body>
        </Modal>

        <Row>
            <Col style={{ margin: "auto" }} xs={12} md={7} lg={6} xl={5}>
                <Card style={{ padding: "1rem" }}>
                    <Card.Body>
                        <div style={{ textAlign: "center" }}>
                            {(props.shop?.logo) && <img height={175} width={175} src={props.shop.logo} />}
                        </div>
                        <Card.Title style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}>Shop is currently closed</Card.Title>
                        <></>
                        <Card.Text style={{ textAlign: "center" }}>Let the shop know that you would like to view their products. Knock on the door and send them an optional note.</Card.Text>
                        <Container>
                            <Row style={{ marginTop: "1rem" }}>
                                <Col>
                                    <EButton id={"submit-btn"} style={{ width: "100%", marginTop: "1rem" }} name="Knock on the door" variant={variant.Primary} onClick={() => props.onActionChange(Action.KnockOnShopDoor)} />
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);