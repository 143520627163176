import axios, { AxiosRequestConfig } from "axios";
import { BasketItem, BasketVariant } from "../state/types";

export interface SetUserCollectionRadiusInput {
    identityToken: string;
    radius: number;
}

export interface SetUserCollectionRadiusOutput {
    statusCode: number;
}

export interface ISetUserCollectionRadius {
    SetUserCollectionRadius(input: SetUserCollectionRadiusInput): Promise<SetUserCollectionRadiusOutput>;
}

export class SetUserCollectionRadius implements ISetUserCollectionRadius {
    async SetUserCollectionRadius(
        input: SetUserCollectionRadiusInput
    ): Promise<SetUserCollectionRadiusOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/me/collection/radius",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                radius: input.radius
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}


export interface AddressDetails {
    id: string;
    address: string;
}

export interface UserSummary {
    username: string;
    address: string;
    addressMetadata: AddressMetadata;
    collectionRadius: number;
}

export interface AddressMetadata {
    id: string;
    lat: number;
    lng: number;
}

export interface SetUserAddressInput {
    identityToken: string;
    addressDetails: AddressDetails;
}

export interface SetUserAddressOutput {
    statusCode: number;
}

export interface ISetUserAddress {
    SetUserAddress(input: SetUserAddressInput): Promise<SetUserAddressOutput>;
}

export class SetUserAddress implements ISetUserAddress {
    async SetUserAddress(
        input: SetUserAddressInput
    ): Promise<SetUserAddressOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/me" +
                "/address",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                id: input.addressDetails.id,
                address: input.addressDetails.address,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetUserSummaryInput {
    identityToken: string;
}

export interface GetUserSummaryOutput {
    statusCode: number;
    body?: UserSummary;
}

export interface IGetUserSummary {
    GetUserSummary(input: GetUserSummaryInput): Promise<GetUserSummaryOutput>;
}

export class GetUserSummary implements IGetUserSummary {
    async GetUserSummary(
        input: GetUserSummaryInput
    ): Promise<GetUserSummaryOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/me",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: response.data as UserSummary,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetNearbyShopsInput {
    addressId: string;
    radius?: number;
}

export interface GetNearbyShopsOutput {
    statusCode: number;
    body?: NearbyShops;
}

export interface IGetNearbyShops {
    GetNearbyShops(input: GetNearbyShopsInput): Promise<GetNearbyShopsOutput>;
}

export interface NearbyShops {
    shops: Array<ShopSummary>;
}

export interface ShopSummary {
    id: string;
    name: string;
    address: string;
}

export class GetNearbyDeliveryShops implements IGetNearbyShops {
    async GetNearbyShops(
        input: GetNearbyShopsInput
    ): Promise<GetNearbyShopsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/nearby/deliveries",
            params: {
                addressId: input.addressId
            }
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: response.data as NearbyShops,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export class GetNearbyCollectionShops implements IGetNearbyShops {
    async GetNearbyShops(
        input: GetNearbyShopsInput
    ): Promise<GetNearbyShopsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/nearby/collections",
            params: {
                addressId: input.addressId,
                radius: input.radius,
            }
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: response.data as NearbyShops,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface Product {
    id?: string;
    name: string;
    price?: number;
    volume?: number;
    uom?: string;
    image?: string;
    stock?: number;
    details?: ProductDetail[];
    category?: string;
    variants?: ProductVariant[];
    variantOverrides?: Map<string, ProductVariantOverride>;
    disabled?: boolean
}

export interface ProductVariantOverride {
    key: string
    price?: number
}

export interface ProductVariant {
    name: string
    options: string[]
}

export interface ProductDetail {
    name: string
    description: string
}

export interface GetProductsInput {
    shopID: string;
}

export interface GetProductsOutput {
    statusCode: number;
    body?: Array<Product>;
}

export interface IGetProducts {
    GetProducts(input: GetProductsInput): Promise<GetProductsOutput>;
}

export class GetProducts implements IGetProducts {
    async GetProducts(input: GetProductsInput): Promise<GetProductsOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/products",
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 200) {
                return {
                    statusCode: response.status,
                    body: [],
                };
            }
            return {
                statusCode: response.status,
                body: response.data.products,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetProductInput {
    shopID: string;
    productID: string;
}

export interface GetProductOutput {
    statusCode: number;
    body?: Product;
}

export interface IGetProduct {
    GetProduct(input: GetProductInput): Promise<GetProductOutput>;
}

export class GetProduct implements IGetProduct {
    async GetProduct(input: GetProductInput): Promise<GetProductOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/products/" +
                input.productID,
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                body: {
                    id: response.data.id,
                    name: response.data.name,
                    price: Number(response.data.price),
                    category: response.data.category,
                    details: response.data.details,
                    image: response.data.image,
                    stock: response.data.stock,
                    uom: response.data.uom,
                    variantOverrides: (response.data.variantOverrides) ? new Map(Object.entries(response.data.variantOverrides)) : undefined,
                    variants: response.data.variants,
                    volume: response.data.volume,
                },
            };
        } catch (err: any) {
            throw err
        }
    }
}

export enum OrderType {
    Delivery = "delivery",
    Collection = "collection"
}

export interface InitPaymentInput {
    identityToken?: string;
    shopID: string;
    type: OrderType;
    items: Array<BasketItem>;
    addressId?: string;
    shopAddressId?: string;
    email?: string;
}

export interface InitPaymentOutput {
    statusCode: number;
    body?: PaymentParameters;
}

export interface PaymentParameters {
    isOnlinePayment: boolean;
    accountId: string;
    paymentClientSecret: string;
    paymentId: string;
    deliveryFee: number;
    deliveryAddress: string;
    deliveryDates: DeliveryDate[];
    serviceFee: number;
    total: number;
}

export interface DeliveryDate {
    day: string;
    interimTime: number;
    timeranges: TimerangeSelector[];
}

export interface TimerangeSelector {
    selected: boolean;
    start: string;
    end: string;
}

export interface IInitPayment {
    InitPayment(input: InitPaymentInput): Promise<InitPaymentOutput>;
    InitGuestPayment(input: InitPaymentInput): Promise<InitPaymentOutput>;
}

export class InitPayment implements IInitPayment {
    async InitPayment(input: InitPaymentInput): Promise<InitPaymentOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/payment/initialize",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
            data: {
                type: input.type,
                items: input.items,
                addressId: input.addressId,
                shopAddressId: input.shopAddressId,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 201) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                body: response.data,
            };
        } catch (err: any) {
            throw err
        }
    }

    async InitGuestPayment(input: InitPaymentInput): Promise<InitPaymentOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "POST",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/payment/guest/initialize",
            data: {
                type: input.type,
                items: input.items,
                addressId: input.addressId,
                email: input.email,
                shopAddressId: input.shopAddressId,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 201) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                body: response.data,
            };
        } catch (err: any) {
            throw err
        }
    }
}

export type GetCustomerOrdersInput = {
    identityToken: string;
};

export type GetCustomerOrdersOutput = {
    statusCode: number;
    body?: GetCustomerOrdersBody;
};

export type GetCustomerOrdersBody = {
    orders: Order[];
};

export type Order = {
    paymentTotal: number;
    status: string;
    timestamp: number;
    items: Item[];
    shop: Shop;
    deliveryDate: OrderDeliveryDate;
    type: string;
};

export type Item = {
    id: string;
    shopId: string;
    name: string;
    uom?: string;
    volume?: number;
    price: number;
    img?: string;
    variants?: BasketVariant[];
}

export type OrderDeliveryDate = {
    day: string;
}

export type Shop = {
    name: string,
    address: string,
    phone: string,
    email: string,
}

export interface IGetCustomerOrders {
    GetCustomerOrders(
        input: GetCustomerOrdersInput
    ): Promise<GetCustomerOrdersOutput | Error>;
}

export class GetCustomerOrders implements IGetCustomerOrders {
    async GetCustomerOrders(
        input: GetCustomerOrdersInput
    ): Promise<GetCustomerOrdersOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: process.env.REACT_APP_CORE_API_HOSTNAME + "/me/orders",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            if (response.status != 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                body: response.data,
            };
        } catch (err: any) {
            if (axios.isAxiosError(err)) {
                return {
                    statusCode: err.response?.status || 500,
                };
            }
            throw err
        }
    }
}

export interface SelectedDeliveryDate {
    day: string;
    time: DeliveryTime;
}

export interface DeliveryTime {
    start: string;
    end: string;
}

export interface SetUserOrderDeliveryDateInput {
    paymentID: string;
    deliveryDate: SelectedDeliveryDate;
}

export interface SetUserOrderDeliveryDateOutput {
    statusCode: number;
}

export interface ISetUserOrderDeliveryDate {
    SetUserOrderDeliveryDate(
        input: SetUserOrderDeliveryDateInput
    ): Promise<SetUserOrderDeliveryDateOutput | Error>;
}

export class SetUserOrderDeliveryDate implements ISetUserOrderDeliveryDate {
    async SetUserOrderDeliveryDate(
        input: SetUserOrderDeliveryDateInput
    ): Promise<SetUserOrderDeliveryDateOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/me/orders/" +
                input.paymentID +
                "/delivery/date",
            data: {
                day: input.deliveryDate.day,
                time: input.deliveryDate.time,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface SetOrderDeliveryDetailsInput {
    paymentID: string;
    details: string;
}

export interface SetOrderDeliveryDetailsOutput {
    statusCode: number;
}

export interface ISetOrderDeliveryDetails {
    SetOrderDeliveryDetails(
        input: SetOrderDeliveryDetailsInput
    ): Promise<SetOrderDeliveryDetailsOutput | Error>;
}

export class SetOrderDeliveryDetails implements ISetOrderDeliveryDetails {
    async SetOrderDeliveryDetails(
        input: SetOrderDeliveryDetailsInput
    ): Promise<SetOrderDeliveryDetailsOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/me/orders/" +
                input.paymentID +
                "/delivery/details",
            data: {
                details: input.details,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface DeleteAccountInput {
    identityToken: string;
}

export interface DeleteAccountOutput {
    statusCode: number;
}

export interface IDeleteAccount {
    DeleteAccount(input: DeleteAccountInput): Promise<DeleteAccountOutput>;
}

export class DeleteAccount implements IDeleteAccount {
    async DeleteAccount(input: DeleteAccountInput): Promise<DeleteAccountOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "DELETE",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/me",
            headers: {
                Authorization: "Bearer " + input.identityToken,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response !== undefined) {
                return {
                    statusCode: err.response.status
                }
            }
            throw err
        }
    }
}

export interface IConfirmOrder {
    ConfirmOrder(input: ConfirmOrderInput): Promise<UpdateOrderStatusOutput | Error>
}

export interface ConfirmOrderInput {
    paymentID: string;
}

export interface UpdateOrderStatusOutput {
    statusCode: number;
}

export class UpdateOrderStatus implements IConfirmOrder {
    async ConfirmOrder(input: ConfirmOrderInput): Promise<UpdateOrderStatusOutput | Error> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/me/orders/" +
                input.paymentID +
                "/confirm",
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetShopContactDetailsOutput {
    statusCode: number;
    body?: ShopContactDetails
}

export interface GetShopContactDetailsInput {
    shopId: string
    address?: AddressDetails
}

export interface ShopContactDetails {
    id: string
    alias: string
    name: string
    addressId: string
    address: string
    area: string
    phone: string
    email: string
    logo?: string
    color?: string
    isDelivery: boolean
    isCollection: boolean
    contacts: ShopContact[]
    paymentInstructions?: string
    paymentForm?: FormElement[]
    active: boolean
}

export interface ShopContact {
    addressId: string
    address: string
}

export interface FormElement {
    type: string
    name: string
}

export interface IGetShopContactDetails {
    GetShopContactDetails(input: GetShopContactDetailsInput): Promise<GetShopContactDetailsOutput | Error>
}

export class GetShopContactDetails implements IGetShopContactDetails {
    async GetShopContactDetails(input: GetShopContactDetailsInput): Promise<GetShopContactDetailsOutput | Error> {
        let url = `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopId}/contact`
        if (input.address && input.address.id !== "") {
            url += `?addressId=${input.address.id}`
        }
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url: url
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: response.data,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface Category {
    id?: string;
    name: string;
}

export interface GetCategoriesOutput {
    statusCode: number;
    categories?: Category[];
}

export interface GetCategoriesInput {
    shopID: string;
}

export interface IGetCategories {
    GetCategories(input: GetCategoriesInput): Promise<GetCategoriesOutput>;
}

export class GetCategories implements IGetCategories {
    async GetCategories(input: GetCategoriesInput): Promise<GetCategoriesOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/product-categories",
        };
        try {
            const response = await axios(requestOptions);
            if (response.status !== 200) {
                return {
                    statusCode: response.status,
                };
            }
            return {
                statusCode: response.status,
                categories: response.data.categories,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetDeliveryInput {
    shopID: string;
}

export interface GetDeliveryOutput {
    statusCode: number;
    delivery?: Delivery;
}

export interface Delivery {
    price: number;
    minimumPrice: number;
    isIntegrated: boolean;
}

export interface IGetDelivery {
    GetDelivery(input: GetDeliveryInput): Promise<GetDeliveryOutput>;
}

export class GetDelivery implements IGetDelivery {
    async GetDelivery(input: GetDeliveryInput): Promise<GetDeliveryOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            url:
                process.env.REACT_APP_CORE_API_HOSTNAME +
                "/shop/" +
                input.shopID +
                "/delivery",
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                delivery: {
                    price: response.data.price,
                    minimumPrice: response.data.minimumPrice,
                    isIntegrated: response.data.isIntegrated
                },
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface UpdateShopCustomerInput {
    shopID: string;
    customerEmail: string;
    isOptInMarketing?: boolean;
}

export interface UpdateShopCustomerOutput {
    statusCode: number;
}

export interface IUpdateShopCustomer {
    UpdateShopCustomer(input: UpdateShopCustomerInput): Promise<UpdateShopCustomerOutput>;
}

export class UpdateShopCustomer implements IUpdateShopCustomer {
    async UpdateShopCustomer(input: UpdateShopCustomerInput): Promise<UpdateShopCustomerOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PATCH",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopID}/customers/${input.customerEmail}`,
            data: {
                isOptInMarketing: input.isOptInMarketing,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface GetCustomerFavoritesInput {
    identityToken: string;
}

export interface GetCustomerFavoritesOutput {
    statusCode: number;
    body?: GetCustomerFavoritesBody;
}

export interface GetCustomerFavoritesBody {
    items: Product[];
}

export interface IGetCustomerFavorites {
    GetCustomerFavorites(input: GetCustomerFavoritesInput): Promise<GetCustomerFavoritesOutput>;
}

export class GetCustomerFavorites implements IGetCustomerFavorites {
    async GetCustomerFavorites(input: GetCustomerFavoritesInput): Promise<GetCustomerFavoritesOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "GET",
            params: {
                type: "products"
            },
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/me/favorites`,
            headers: {
                Authorization: `Bearer ${input.identityToken}`,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
                body: response.data
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface SetCustomerFavoriteInput {
    identityToken: string;
    type: string;
    itemId: string;
    item: any;
}

export interface SetCustomerFavoriteOutput {
    statusCode: number;
}

export interface ISetCustomerFavorite {
    SetCustomerFavorite(input: SetCustomerFavoriteInput): Promise<SetCustomerFavoriteOutput>;
}

export class SetCustomerFavorite implements ISetCustomerFavorite {
    async SetCustomerFavorite(input: SetCustomerFavoriteInput): Promise<SetCustomerFavoriteOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/me/favorites/${input.itemId}`,
            headers: {
                Authorization: `Bearer ${input.identityToken}`,
            },
            data: {
                type: input.type,
                item: input.item,
            }
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface DeleteCustomerFavoriteInput {
    identityToken: string;
    itemId: string;
}

export interface DeleteCustomerFavoriteOutput {
    statusCode: number;
}

export interface IDeleteCustomerFavorite {
    DeleteCustomerFavorite(input: DeleteCustomerFavoriteInput): Promise<DeleteCustomerFavoriteOutput>;
}

export class DeleteCustomerFavorite implements IDeleteCustomerFavorite {
    async DeleteCustomerFavorite(input: SetCustomerFavoriteInput): Promise<SetCustomerFavoriteOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "DELETE",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/me/favorites/${input.itemId}`,
            headers: {
                Authorization: `Bearer ${input.identityToken}`,
            },
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}

export interface SendMessageInput {
    shopId: string;
    type: string;
    email: string;
    message: string;
}

export interface SendMessageOutput {
    statusCode: number;
}

export interface ISendMessage {
    SendMessage(input: SendMessageInput): Promise<SendMessageOutput>;
}

export class SendMessage implements ISendMessage {
    async SendMessage(input: SendMessageInput): Promise<SendMessageOutput> {
        const requestOptions: AxiosRequestConfig = {
            method: "PUT",
            url: `${process.env.REACT_APP_CORE_API_HOSTNAME}/shop/${input.shopId}/send-message`,
            data: {
                type: input.type,
                email: input.email,
                message: input.message
            }
        };
        try {
            const response = await axios(requestOptions);
            return {
                statusCode: response.status,
            };
        } catch (err: any) {
            if (err.response) {
                return {
                    statusCode: err.response.status,
                };
            }
            throw err
        }
    }
}