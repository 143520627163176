export interface Basket {
    type: BasketType;
    items: BasketItem[];
}

export enum BasketType {
    Delivery = "delivery",
    Collection = "collection"
}

export interface BasketItem {
    id: string;
    shopId: string;
    name: string;
    uom?: string;
    volume?: number;
    price: number;
    img?: string;
    variants?: BasketVariant[];

    // productVolume is the quantity is which the shop sells there product by e.g. 100(g)
    productVolume: number;
    // productPrice is the price for the specified product volume e.g. 100(g) = £0.45
    productPrice: number;
}

export interface BasketVariant {
    name: string
    value: string
}

export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ALL = "DELETE_ALL";
export const UPDATE_TYPE = "UPDATE_TYPE";

export interface BasketAction {
    type: string;
    payload?: BasketItem | BasketType;
}
