import React, { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";

import { IGetNearbyShops, ShopSummary } from "../../client/core";
import { RootState } from "../../state";
import { ConnectedProps, connect } from "react-redux";
import { GetNearbyShopsOutput } from "../../client/core";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { Color, Path, getPrimaryColor } from "../../env";

export enum Field {
    Username = "username",
    Password = "password",
    Email = "email",
}

export interface NearbyShopsProps {
    addressId: string;
    title: string;
    getNearbyShopsAPI: IGetNearbyShops;
    radius: number;
}

export interface NearbyShopsState {
    isLoading: boolean
    error: string;
    shops: Array<ShopSummary>;
}

export class NearbyShops extends Component<NearbyShopsProps, NearbyShopsState> {
    constructor(props: NearbyShopsProps) {
        super(props);
        this.state = {
            isLoading: true,
            error: "",
            shops: new Array(),
        };
    }

    componentDidMount(): void {
        if (!this.props.addressId) {
            return this.setState({
                isLoading: false
            })
        }
        this.getNearbyShops()
    }

    componentDidUpdate(prevProps: Readonly<NearbyShopsProps>, prevState: Readonly<NearbyShopsState>, snapshot?: any): void {
        if (prevProps.addressId != this.props.addressId) {
            this.setState({
                isLoading: true
            })
            this.getNearbyShops()
        }
    }

    getNearbyShops = () => {
        this.props.getNearbyShopsAPI
            .GetNearbyShops({
                addressId: this.props.addressId,
                radius: this.props.radius,
            })
            .then((response: GetNearbyShopsOutput) => {
                if (response.statusCode === 403) {
                    return
                }

                if (response.statusCode !== 200) {
                    return this.setState({
                        error: "Could not find any shops in your location.",
                    });
                }

                if (response.body?.shops!.length == 0) {
                    return this.setState({
                        error: "Thank you for your interest in our platform! While we're a relatively new company, we're working hard to onboard the best shops in your area.",
                    });
                }

                this.setState({
                    shops: response.body?.shops!,
                    error: "",
                });
            })
            .catch((err: Error) => {
                return this.setState({
                    error: "Could not find any shops in your location.",
                });
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        const props: NearbyShopsListProps = {
            error: this.state.error,
            shops: this.state.shops,
            isLoading: this.state.isLoading,
        };
        return <NearbyShopsList {...props} />;
    }
}


const mapState = (state: RootState) => ({
    addressId: state.account.data?.address?.metadata.id!,
    radius: state.account.data?.collection?.radius!,
});

const connector = connect(mapState, {});

export default connector(NearbyShops);

export interface NearbyShopsListProps {
    isLoading: boolean;
    error: string;
    shops: Array<ShopSummary>;
}

export const NearbyShopsList: FunctionComponent<NearbyShopsListProps> = (
    props
) => (
    <Row>

        {(props.isLoading) && <Row style={{ textAlign: "center" }}>
            <Spinner style={{ margin: "auto", color: getPrimaryColor() }} animation="border" />
        </Row>}
        {(props.error != "") && (
            <div style={{ padding: "1rem" }}>
                <Card style={{ height: "100%" }}>
                    <Card.Body>
                        <Card.Title>No shops found in your area.</Card.Title>
                        <Card.Text>{props.error}</Card.Text>
                    </Card.Body>
                </Card>
            </div>
        )}
        {props.shops.map(shop => (
            <Col key={shop.id} xs={12} md={6} lg={4}>
                <Shop shop={shop} />
            </Col>
        ))}
    </Row>
)

export interface ShopProps {
    shop: ShopSummary;
}

export const Shop: FunctionComponent<ShopProps> = (
    props
) => (
    <a style={{ textDecoration: "none", color: Color.DarkGrey }} href={`${Path.Shop}/${props.shop.id}`}>
        <Card style={{ height: "100%" }}>
            <Card.Body>
                <Card.Title>{props.shop.name}</Card.Title>
                <Card.Text>{props.shop.address}</Card.Text>
            </Card.Body>
        </Card>
    </a>
)

