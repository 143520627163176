import { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";
import { IGetProduct, IGetShopContactDetails, Product as ProductDto, ShopContactDetails } from "../../client/core";
import { RouteComponentProps } from "react-router";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { ADD_ITEM, AccountAction, BasketAction, BasketItem, Shop as ShopDto } from "../../state/types";
import { Color, Path, getPrimaryColor } from "../../env";
import { ActiveShopAction, Shop as ShopSummary, ShopsAction, UPDATE_SHOPS } from "../../state/types/shop";
import { ProductView, getVariantKey, getVariantOverride } from "./Shop";
import { Button, icon, variant } from "../form/Button";
import { Dispatch } from "redux";

type TParams = { shopId: string, productId: string };

export type BasketDispatch = Dispatch<BasketAction>;

export type RouteShopProps = RouteComponentProps<TParams>;

export type ShopDispatch = Dispatch<ActiveShopAction>;

export type ShopsDispatch = Dispatch<ShopsAction>;

export type AccountDispatch = Dispatch<AccountAction>;

export interface ProductProps extends RouteShopProps {
    auth: typeof Auth;
    getShopContactAPI: IGetShopContactDetails;
    getProductAPI: IGetProduct;
    basketDispatch: BasketDispatch;
    activeShopDispatch: ShopDispatch;
    shopsDispatch: ShopsDispatch;
    basket: BasketItem[];
    shop?: ShopSummary;
}

export interface ProductState {
    isLoading: boolean;
    error: string;
    product?: ProductDto;
    item?: BasketItem;
}

export class Product extends Component<
    ProductProps,
    ProductState
> {
    constructor(props: ProductProps) {
        super(props);
        this.state = {
            isLoading: true,
            error: "",
        };
    }

    componentDidMount() {
        this.getShopSummary().then(() => {
            this.getProduct()
        })
    }

    getProduct = async () => {
        this.props.getProductAPI.GetProduct({
            shopID: this.props.match.params.shopId,
            productID: this.props.match.params.productId,
        }).then(response => {
            if (response.statusCode !== 200 || !response.body) {
                return this.setState({
                    error: "Could not retrieve shop products at this time. Please try again later.",
                })
            }

            this.setState({
                product: response.body,
                item: {
                    id: response.body.id!,
                    name: response.body.name,
                    price: response.body.price!,
                    shopId: this.props.shop!.id,
                    img: response.body.image,
                    volume: 0,
                    uom: response.body.uom,
                    productPrice: response.body.price!,
                    productVolume: response.body.volume || 1,
                }
            })
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    getShopSummary = async () => {
        const response = await this.props.getShopContactAPI.GetShopContactDetails({
            shopId: this.props.match.params.shopId,
        })

        if (response instanceof Error) {
            return this.setState({
                error: "Could not retrieve shop details at this time. Please try again later."
            })
        }

        if (response.statusCode !== 200 || !response.body) {
            return this.setState({
                error: "Could not retrieve shop details at this time. Please ensure this shop link is correct."
            })
        }

        console.log(response)

        return this.props.activeShopDispatch({
            type: "UPDATE_SHOP",
            payload: {
                addressId: response.body.addressId,
                address: response.body.address,
                id: response.body.id,
                name: response.body.name,
                email: response.body.email,
                phone: response.body.phone,
                logo: response.body.logo,
                isCollection: response.body.isCollection || false,
                isDelivery: response.body.isDelivery || false,
                paymentInstructions: response.body.paymentInstructions,
                area: response.body.area,
                paymentForm: response.body.paymentForm,
                color: response.body.color,
                contacts: response.body.contacts,
                active: response.body.active,
            }
        })
    }

    handleProductChange = (item: BasketItem): void => {
        this.setState({
            item: item,
        })
    }

    handleProductSubmit = (product: ProductDto, item: BasketItem): void => {
        if (product.variants && (!item.variants || product.variants.length !== item.variants.length)) {
            return this.setState({
                error: "Please select all options before adding item to basket."
            })
        }
        const variantOverride = getVariantOverride(getVariantKey(item.variants), product.variantOverrides)
        const productPrice = (variantOverride && variantOverride.price) ? variantOverride.price : product.price!
        const price = (item.volume) ? (Math.round((item.volume / (product.volume || 1))) * (productPrice! * 100)) / 100 : 0
        this.props.basketDispatch({
            type: ADD_ITEM,
            payload: {
                id: item.id!,
                name: product.name,
                price: price,
                shopId: this.props.shop!.id,
                uom: product.uom,
                volume: item.volume || 0,
                img: product.image,
                variants: item.variants,
                productPrice: productPrice,
                productVolume: product.volume || 1
            }
        })
        this.props.history.replace(`${Path.Shop}/${this.props.shop?.id!}`)
    }

    handleNavigate = (path: string): void => {
        this.props.history.replace(path)
    }

    render() {
        if (this.state.isLoading) {
            return (
                <Row style={{ textAlign: "center" }}>
                    <Spinner style={{ margin: "auto", color: getPrimaryColor() }} animation="border" />
                </Row>
            )
        }

        const productViewProps: ProductPageProps = {
            error: this.state.error,
            product: this.state.product!,
            item: this.state.item!,
            shop: this.props.shop!,
            onProductSubmit: this.handleProductSubmit,
            onItemChange: this.handleProductChange,
            onNavigate: this.handleNavigate,
        };
        return (
            <ProductPage {...productViewProps} />
        );
    }
}

const mapState = (state: RootState) => ({
    basket: state.basket.items,
    shop: state.shop.shop,
});

const connector = connect(mapState, {});

export default connector(Product);

export interface ProductPageProps {
    error: string;
    item: BasketItem;
    product: ProductDto;
    shop: ShopSummary;
    onProductSubmit: (product: ProductDto, item: BasketItem) => void;
    onItemChange: (item: BasketItem) => void;
    onNavigate: (path: string) => void;
}

export const ProductPage: FunctionComponent<ProductPageProps> = (
    props
) => (
    <Container style={{ marginTop: "10rem" }}>
        <div style={{ position: "fixed", zIndex: 12, top: "4.8rem", left: 0, right: 0, background: Color.LightGrey, borderBottom: "1px solid lightgrey" }}>
            <Container>
                <Row>
                    <Col>
                        <Button icon={icon.LeftArrow} iconColor={Color.Grey} style={{ paddingLeft: 0, fontSize: "1.5rem", fontColor: Color.DarkGrey }} onClick={() => props.onNavigate(`${Path.Shop}/${props.shop!.id}`)} variant={variant.Transparent} />
                    </Col>
                    <Col style={{ textAlign: "center", display: "table" }}>
                        <a style={{ textDecoration: "none", color: Color.Grey, fontWeight: 600, display: "table-cell", verticalAlign: "middle" }} href={`${Path.Shop}/${props.shop!.id}`}>{`Browse more products`}</a>
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                        <Button icon={icon.RightArrow} iconColor={Color.Grey} style={{ paddingLeft: 0, fontSize: "1.5rem", fontColor: Color.DarkGrey }} onClick={() => props.onNavigate(`${Path.Shop}/${props.shop!.id}`)} variant={variant.Transparent} />
                    </Col>
                </Row>
            </Container>
        </div>
        <Alert variant={"danger"} show={props.error != ""}>
            {props.error}
        </Alert>
        <ProductView
            isFavorite={false}
            error={props.error}
            onSubmit={props.onProductSubmit}
            item={props.item}
            product={props.product!}
            onItemChange={props.onItemChange}
        />
    </Container>
);