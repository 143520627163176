import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { ActiveShopAction, Shop } from "./state/types";

export enum Storage {
    IsLoggedIn = "IsLoggedIn",
    ShopColor = "ShopColor"
}


export enum Path {
    Home = "/",
    Dashboard = "/dashboard",
    Login = "/login",
    ResetPassword = "/reset-password",
    RegisterMember = "/register",
    RegisterConfirmation = "/register/member/confirmation",
    Checkout = "/checkout",
    CheckoutAsGuest = "/checkout-as-guest",
    Basket = "/basket",
    Shop = "/shop",
    PaymentConfirmation = "/payment-confirmation",
    OrderConfirmation = "/order-confirmation",
    Product = "/product",
    Account = "/account",
    AccountDelete = "/account/delete",
    AccountDeleteConfirmation = "/account/delete/confirmation",
    SetAddress = "/action/address"
}

export enum Color {
    Primary = "#5b5bff",
    Brown = "#552200",
    Grey = "#C0C0C0",
    DarkGrey = "#202124",
    LightGrey = "#F7F7F7",
    White = "#FFFFFF",
    Red = "#d9534f"
}

export const Sizes = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1536px',
};

export function getPrimaryColor() {
    const color: string | null = localStorage.getItem(Storage.ShopColor)
    return (color) ? color : Color.Primary
}