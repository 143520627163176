import { JourneyAction, JourneyState, UPDATE_JOURNEY } from "../types/journey";

const INITIAL_STATE: JourneyState = {};

export const journeyReducer = (state = INITIAL_STATE, action: JourneyAction): JourneyState => {
    switch (action.type) {
        case UPDATE_JOURNEY:
            return {
                journey: action.payload
            }
        default:
            return state;
    }
};
