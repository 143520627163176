import { ADD_ITEM, Basket, BasketType, BasketAction, DELETE_ALL, DELETE_ITEM, BasketItem, UPDATE_TYPE, BasketVariant } from "../types/basket";

const INITIAL_STATE: Basket = {
    type: BasketType.Delivery,
    items: [],
};

function arraysEqual(arr1: BasketVariant[], arr2: BasketVariant[]): boolean {
    if (arr1.length !== arr2.length) {
        return false;
    }

    return JSON.stringify(arr1.sort()) === JSON.stringify(arr2.sort())
}

export const basketReducer = (state = INITIAL_STATE, action: BasketAction): Basket => {
    let { items } = state;
    switch (action.type) {
        case UPDATE_TYPE:
            return {
                ...state,
                type: action.payload as BasketType
            }
        case ADD_ITEM:
            let addItemPayload = action.payload as BasketItem
            items = items.filter((item) => {
                const isSameID: boolean = (item.id === addItemPayload?.id!)
                if (!item.variants || !addItemPayload.variants) {
                    return !isSameID
                }
                const isSameVariant: boolean = arraysEqual(item.variants, addItemPayload.variants)
                return !isSameID || !isSameVariant
            });
            if (addItemPayload?.volume! <= 0) {
                return {
                    ...state,
                    items: items,
                };
            }
            items.push(addItemPayload!);
            items = items.sort((a, b) => a.name.localeCompare(b.name))
            return {
                ...state,
                items: items,
            };
        case DELETE_ITEM:
            let deleteItemPayload = action.payload as BasketItem
            items = items.filter((item) => item.id !== deleteItemPayload?.id!);
            return {
                ...state,
                items: items,
            };
        case DELETE_ALL:
            return {
                ...state,
                items: [],
            };
        default:
            return state;
    }
};
