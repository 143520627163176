export interface Account {
    data: AccountData | undefined
}

export interface AccountData {
    isUser?: boolean
    username?: string
    address?: Address
    collection?: Collection
    email?: string
    isMarketing?: boolean
    lastUpdated?: number 
}

export interface Collection {
    radius: number
}

export interface Address {
    value: string
    metadata: AddressMetadata
}

export interface AddressMetadata {
    id: string
}

export const UPDATE_ACCOUNT = "UPDATE";
export const DELETE_ACCOUNT = "DELETE";

export interface AccountAction {
    type: string;
    payload?: Account;
}