import { FunctionComponent } from "react"
import { Button as BButton } from "react-bootstrap"
import { FaArrowLeft, FaArrowRight, FaCross, FaEdit, FaFacebookF, FaGoogle, FaHeart, FaPlus, FaSearch, FaShoppingBasket, FaTrash } from "react-icons/fa"
import { IoClose, IoTrashBinSharp } from "react-icons/io5"
import { Color, getPrimaryColor } from "../../env"
import { SlBasket } from "react-icons/sl"

export enum variant {
    Primary,
    Secondary,
    Danger,
    Transparent,
    Border,
}

export interface ButtonProps {
    id?: string
    name?: string
    variant: variant
    icon?: icon
    iconColor?: Color
    style?: any
    disabled?: boolean
    color?: Color
    onClick: () => void
}

export enum icon {
    Trash,
    Plus,
    RightArrow,
    LeftArrow,
    Search,
    Edit,
    Facebook,
    Google,
    Heart,
    Basket,
    Close,
}

export const Button: FunctionComponent<ButtonProps> = (props) => {

    const getColor = () => {
        switch (props.variant) {
            case variant.Secondary:
                return Color.Grey
            case variant.Danger:
                return Color.Red
            case variant.Transparent:
                return "none"
            case variant.Border:
                return "none"
            default:
                return getPrimaryColor()
        }
    }

    const getBorder = () => {
        switch (props.variant) {
            case variant.Border:
                return `1px solid ${props.color || getPrimaryColor()}`
            default:
                return 0
        }
    }

    return (
        <BButton
            id={props.id}
            style={{ ...props.style, background: getColor(), border: getBorder(), borderRadius: 0 }}
            onClick={() => props.onClick()}
            disabled={props.disabled}
        >
            {(props.icon) && <Icon color={props.iconColor} icon={props.icon!} />} {props.name}
        </BButton>
    )
}

export interface IconProps {
    icon: icon
    color?: Color
    size?: number
}

export const Icon: FunctionComponent<IconProps> = (props) => {
    switch (props.icon) {
        case icon.Trash:
            return <IoTrashBinSharp {...props} />
        case icon.Plus:
            return <FaPlus {...props} />
        case icon.LeftArrow:
            return <FaArrowLeft {...props} />
        case icon.RightArrow:
            return <FaArrowRight {...props} />
        case icon.Search:
            return <FaSearch {...props} />
        case icon.Edit:
            return <FaEdit {...props} />
        case icon.Facebook:
            return <FaFacebookF color={props.color} size={props.size} />
        case icon.Google:
            return <FaGoogle color={props.color} size={props.size} />
        case icon.Heart:
            return <FaHeart {...props} />
        case icon.Basket:
            return <SlBasket {...props} />
        case icon.Close:
            return <IoClose {...props} />
        default:
            return <div></div>
    }
}
