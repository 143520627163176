import { Component, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { Card, Col, Row } from "react-bootstrap";
import { Color, Path, getPrimaryColor } from "../../env";
import { FaCheckCircle, FaRegCheckCircle } from 'react-icons/fa';
import { Button, variant } from "../form/Button";
import { Dispatch } from "redux";
import { BasketAction, DELETE_ALL, Journey } from "../../state/types";
import { RootState } from "../../state";
import { connect } from "react-redux";

export type CheckoutDispatch = Dispatch<BasketAction>;

export interface PaymentConfirmationProps extends RouteComponentProps {
    basketDispatch: CheckoutDispatch;
    journey?: Journey;
}

export interface PaymentConfirmationState { }

export class PaymentConfirmation extends Component<
    PaymentConfirmationProps,
    PaymentConfirmationState
> {
    constructor(props: PaymentConfirmationProps) {
        super(props);
        this.state = {};
    }

    handleNavigate = (path: string) => {
        this.props.history.push(path);
    };

    componentDidMount(): void {
        this.props.basketDispatch({
            type: DELETE_ALL,
        });
    }

    render() {
        const basketViewProps: PaymentConfirmationViewProps = {
            journey: this.props.journey,
            onNavigate: this.handleNavigate,
        };
        return <PaymentConfirmationView {...basketViewProps} />
    }
}

const mapState = (state: RootState) => ({
    journey: state.journey.journey,
});

const connector = connect(mapState, {});

export default connector(PaymentConfirmation);

export interface PaymentConfirmationViewProps {
    journey?: Journey;
    onNavigate: (path: string) => void;
}

export const PaymentConfirmationView: FunctionComponent<PaymentConfirmationViewProps> = (
    props
) => {
    return (
        <Row>
            <Col style={{ margin: "auto" }} xs={12} md={8}>
                <Card>
                    <Card.Body style={{ textAlign: "center" }}>
                        <FaRegCheckCircle color={getPrimaryColor()} size={60} />
                        <Card.Title style={{ color: getPrimaryColor(), fontSize: "1.8rem", marginTop: "1rem", fontWeight: 400 }}>Payment Successful!</Card.Title>
                        <Card.Text style={{ fontSize: "1.2rem", marginTop: "1rem" }}>Your payment has been processed successfully</Card.Text>
                        <Card.Text style={{ marginBottom: "1.3rem" }}>You'll receive an email shortly.</Card.Text>
                        <Button onClick={() => props.onNavigate(props.journey?.base || Path.Dashboard)} variant={variant.Primary} name="Continue shopping" />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}