import { FunctionComponent } from "react";
import { Card } from "react-bootstrap";

export const MemberRegisterConfirmation: FunctionComponent<{}> = () => (
    <div>
        <Card>
            <Card.Body>
                <Card.Title>We have sent you an email</Card.Title>
                <Card.Text>
                    You should have recieved an email confirming that you are the owner
                    of the email address given. Once you have opened the email and
                    followed the instructions your membership will be complete and you
                    will be able to <a href="/login">login</a>
                </Card.Text>
            </Card.Body>
        </Card>
    </div>
);
