import { Component, FormEvent, FunctionComponent } from "react";
import {
    Form,
    Alert,
    Card,
    Container,
    Row,
    Col,
    ButtonGroup,
    ToggleButton,
} from "react-bootstrap";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Color, Path, Storage, getPrimaryColor } from "../../env";
import { Button, Icon, icon, variant } from "../form/Button";
import { Dispatch } from "redux";
import { AccountAction, AccountData, Shop, UPDATE_ACCOUNT } from "../../state/types";
import { RootState } from "../../state";
import { connect } from "react-redux";
import { IUpdateShopCustomer } from "../../client/core";


export type AccountDispatch = Dispatch<AccountAction>;

export interface CheckoutAsGuestState {
    fields: Map<formAttribute, any>;
    isLoading: boolean;
    error: string;
    currentOption: option;
}

export interface CheckoutAsGuestProps extends RouteComponentProps {
    auth: typeof Auth;
    accountDispatch: AccountDispatch;
    updateShopCustomer: IUpdateShopCustomer;
    shop?: Shop;
    account?: AccountData
}

export enum formAttribute {
    username = "username",
    password = "password",
    email = "email",
    isOptInMarketing = "isOptInMarketing"
}

export enum option {
    signIn = "signIn",
    guest = "guest",
}

export class CheckoutAsGuest extends Component<CheckoutAsGuestProps, CheckoutAsGuestState> {
    constructor(props: CheckoutAsGuestProps) {
        super(props);
        this.state = {
            isLoading: false,
            fields: new Map<formAttribute, any>([
                [formAttribute.username, ""],
                [formAttribute.password, ""],
                [formAttribute.email, props.account?.email || ""],
                [formAttribute.isOptInMarketing, false],
            ]),
            error: "",
            currentOption: option.signIn,
        };
    }

    handleChange = (name: formAttribute, value: any) => {
        const fields = this.state.fields;
        fields.set(name, value);
        this.setState({
            fields: fields,
        });
    };

    handleNavigate = (path: Path) => {
        this.props.history.push(path);
    };

    isValidField = (
        field: formAttribute,
        fields: Map<formAttribute, string>
    ): boolean => {
        return (
            typeof fields.get(field) !== "undefined" &&
            fields.get(field)!.trim() !== ""
        );
    };

    handleLoginSubmit = () => {
        if (
            this.isValidField(formAttribute.password, this.state.fields) ===
            false
        ) {
            const error = "Please provide a password";
            return this.setState({
                error: error,
            });
        }

        if (
            this.isValidField(formAttribute.username, this.state.fields) ===
            false
        ) {
            const error = "Please provide your username";
            return this.setState({
                error: error,
            });
        }

        const password: string = this.state.fields
            .get(formAttribute.password)!
            .trim();
        const username: string = this.state.fields
            .get(formAttribute.username)!
            .trim();

        this.setState({
            isLoading: true,
        })

        this.props.auth
            .signIn(username, password)
            .then(() => {
                localStorage.setItem(Storage.IsLoggedIn, "true");
                this.props.history.replace(Path.Checkout);
            })
            .catch((err) => {
                this.setState({
                    error: err.message,
                });
            }).finally(() => {
                this.setState({
                    isLoading: false,
                })
            })
    };

    handleGuestSubmit = () => {
        if (
            this.isValidField(formAttribute.email, this.state.fields) ===
            false
        ) {
            const error = "Please provide an email to receive order notifications";
            return this.setState({
                error: error,
            });
        }

        this.handleOptInMarketing()

        const email: string = this.state.fields
            .get(formAttribute.email)!
            .trim();

        const isOptInMarketing: boolean = this.state.fields
            .get(formAttribute.isOptInMarketing) || false;

        this.props.accountDispatch({
            type: UPDATE_ACCOUNT,
            payload: {
                data: {
                    email: email,
                    isMarketing: isOptInMarketing,
                }
            }
        })

        this.props.history.push(Path.Checkout);
    };

    handleOptInMarketing = () => {
        if (!this.props.shop?.id) {
            return
        }

        if (!this.state.fields.has(formAttribute.email)) {
            return
        }

        const email: string = this.state.fields
            .get(formAttribute.email)!
            .trim();

        const isOptInMarketing: boolean = this.state.fields
            .get(formAttribute.isOptInMarketing) || false;

        if (!isOptInMarketing) {
            return
        }

        this.props.updateShopCustomer.UpdateShopCustomer({
            customerEmail: email,
            shopID: this.props.shop.id,
            isOptInMarketing: isOptInMarketing
        }).catch(() => {
            const {fields} = this.state
            fields.set(formAttribute.isOptInMarketing, false)
            this.setState({
                fields: fields
            })
        })
    }

    handleOptionSelect = (option: option) => {
        this.setState({
            currentOption: option,
        })
    }

    handleFederatedLogin = async (type: CognitoHostedUIIdentityProvider) => {
        this.props.auth.federatedSignIn({
            provider: type,
        })
    }

    render() {
        const credentialFormProps: CheckoutAsGuestFormProps = {
            account: this.props.account,
            shop: this.props.shop,
            username: this.state.fields.get(formAttribute.username)!,
            password: this.state.fields.get(formAttribute.password)!,
            email: this.state.fields.get(formAttribute.email)!,
            isOptInMarketing: this.state.fields.get(formAttribute.isOptInMarketing)!,
            error: this.state.error,
            isLoading: this.state.isLoading,
            currentOption: this.state.currentOption,
            onChange: this.handleChange,
            onLoginSubmit: this.handleLoginSubmit,
            onGuestSubmit: this.handleGuestSubmit,
            onNavigate: this.handleNavigate,
            onOptionSelect: this.handleOptionSelect,
            onFederatedLogin: this.handleFederatedLogin
        };
        return <CheckoutAsGuestForm {...credentialFormProps} />;
    }
}

const mapState = (state: RootState) => ({
    shop: state.shop.shop,
    account: state.account.data
});

const connector = connect(mapState, {});

export default connector(CheckoutAsGuest);

export interface CheckoutAsGuestFormProps {
    shop?: Shop;
    account?: AccountData;
    username: string;
    password: string;
    email: string;
    isOptInMarketing: boolean;
    error: string;
    isLoading: boolean;
    currentOption: option;
    onChange: (name: formAttribute, value: any) => void;
    onNavigate: (path: Path) => void;
    onOptionSelect: (option: option) => void;
    onLoginSubmit: () => void;
    onGuestSubmit: () => void;
    onFederatedLogin: (type: CognitoHostedUIIdentityProvider) => void;
}

export const CheckoutAsGuestForm: FunctionComponent<CheckoutAsGuestFormProps> = (props) => (
    <Container fluid>
        <Row>
            <Col xs={12} md={5} style={{ margin: "auto" }}>
                <Card>
                    <Card.Body>
                        <Button style={{ marginBottom: "1rem" }} variant={variant.Secondary} icon={icon.LeftArrow} onClick={() => props.onNavigate(Path.Basket)} />
                        <Card.Title style={{ textAlign: "center" }}>Just before you checkout...</Card.Title>
                        <Card.Text style={{ textAlign: "center" }}>Please choose how you would like to proceed</Card.Text>
                        <div style={{ textAlign: "center" }}>
                            <ButtonGroup className="mb-2">
                                <ToggleButton
                                    style={{ borderRadius: 0, borderColor: getPrimaryColor(), background: (props.currentOption == option.signIn) ? getPrimaryColor() : "none", color: (props.currentOption == option.signIn) ? Color.White : getPrimaryColor() }}
                                    id="toggle-check"
                                    type="checkbox"
                                    variant="outline-secondary"
                                    checked={props.currentOption == option.signIn}
                                    value="1"
                                    onClick={() => props.onOptionSelect(option.signIn)}
                                >
                                    Sign in
                                </ToggleButton>
                                <ToggleButton
                                    style={{ borderRadius: 0, borderColor: getPrimaryColor(), background: (props.currentOption == option.guest) ? getPrimaryColor() : "none", color: (props.currentOption == option.guest) ? Color.White : getPrimaryColor() }}
                                    id="toggle-check"
                                    type="checkbox"
                                    variant="outline-secondary"
                                    checked={props.currentOption == option.guest}
                                    value="1"
                                    onClick={() => props.onOptionSelect(option.guest)}
                                >
                                    Continue as guest
                                </ToggleButton>
                            </ButtonGroup>
                        </div>
                        {(props.currentOption === option.signIn) && <SignInForm onSubmit={props.onLoginSubmit} {...props} />}
                        {(props.currentOption === option.guest) && <GuestForm onSubmit={props.onGuestSubmit} {...props} />}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
);


export interface SignInFormProps {
    username: string;
    password: string;
    error: string;
    isLoading: boolean;
    onFederatedLogin: (type: CognitoHostedUIIdentityProvider) => void;
    onChange: (name: formAttribute, value: any) => void;
    onNavigate: (path: Path) => void;
    onSubmit: () => void;
}

export const SignInForm: FunctionComponent<SignInFormProps> = (props) => (
    <Container>
        <Row>
            <Col>
                <Alert
                    variant={"danger"}
                    show={props.error != ""}
                >
                    {props.error}
                </Alert>
            </Col>
        </Row>

        <Form>
            <Row style={{ marginTop: "1rem" }}>
                <Col>
                    <Form.Group controlId="formBasicUsername">
                        <Form.Label style={{ fontSize: "0.9rem" }}>Username</Form.Label>
                        <Form.Control
                            id={"username"}
                            type="text"
                            name={"username"}
                            onChange={(e) =>
                                props.onChange(
                                    formAttribute.username,
                                    e.target.value
                                )
                            }
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label style={{ fontSize: "0.9rem", marginTop: ".6rem" }}>Your password</Form.Label>
                        <Form.Control
                            id={"password"}
                            type="password"
                            name={"password"}
                            onChange={(e) =>
                                props.onChange(
                                    formAttribute.password,
                                    e.target.value
                                )
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Form>

        <Row style={{ marginTop: "1rem" }}>
            <Col style={{ textAlign: "center" }}>
                <Button id={"login-submit-btn"} disabled={props.isLoading} style={{ width: "100%", margin: "1rem 0 1.2rem 0" }} name="Submit" variant={variant.Primary} onClick={props.onSubmit} />
                <span>Don't have an account? <a
                    style={{
                        color: getPrimaryColor(),
                        cursor: "pointer",
                    }}
                    onClick={() =>
                        props.onNavigate(
                            Path.RegisterMember
                        )
                    }
                >
                    Register
                </a></span>
            </Col>
        </Row>

        <div style={{ width: "100%", height: "14px", borderBottom: `1px solid ${Color.Grey}`, textAlign: "center", marginBottom: "1rem", marginTop: "1rem" }}>
            <span style={{ padding: "0 10px", backgroundColor: Color.White, color: Color.DarkGrey }}>
                Or
            </span>
        </div>

        <Row>
            <Col xs={12}>
                <button style={{ border: 0, background: "#436BC6", padding: ".4rem", color: Color.White, width: "100%", marginTop: "1rem" }} onClick={() => props.onFederatedLogin(CognitoHostedUIIdentityProvider.Facebook)}><Icon icon={icon.Facebook} size={30} /> Continue with Facebook</button>
                <button style={{ border: 0, background: "#DE4A3A", padding: ".4rem", color: Color.White, width: "100%", marginTop: "1rem" }} onClick={() => props.onFederatedLogin(CognitoHostedUIIdentityProvider.Google)}><Icon icon={icon.Google} size={30} /> Continue with Google</button>
            </Col>
        </Row>
    </Container>
);

export interface GuestFormProps {
    shop?: Shop;
    account?: AccountData;
    email: string;
    isOptInMarketing: boolean;
    error: string;
    isLoading: boolean;
    onChange: (name: formAttribute, value: any) => void;
    onNavigate: (path: Path) => void;
    onSubmit: () => void;
}

export const GuestForm: FunctionComponent<GuestFormProps> = (props) => (
    <Form>
        <Container>
            <Row>
                <Col>
                    <Alert
                        variant={"danger"}
                        show={props.error != ""}
                    >
                        {props.error}
                    </Alert>
                </Col>
            </Row>
            <Row style={{ marginTop: "1rem" }}>
                <Col>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label style={{ fontSize: "0.9rem" }}>Email</Form.Label>
                        <Form.Control
                            id={"email"}
                            type="text"
                            name={"email"}
                            value={props.email}
                            onChange={(e) =>
                                props.onChange(
                                    formAttribute.email,
                                    e.target.value
                                )
                            }
                        />
                        <Form.Text className="text-muted">
                            This email is required to send order notifications
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>
            {(!props.account?.isMarketing) && <Row style={{ marginTop: "1rem" }}>
                <Col>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Check
                            id={"isMarketingOptIn"}
                            type="checkbox"
                            name={"isMarketingOptIn"}
                            label={`Yes, I want to stay in the loop with the ${props.shop?.name} exclusive offers, promotions, and exciting updates!`}
                            checked={props.isOptInMarketing}
                            onChange={() =>
                                props.onChange(
                                    formAttribute.isOptInMarketing,
                                    !props.isOptInMarketing,
                                )
                            }
                        />
                    </Form.Group>
                </Col>
            </Row>}
            <Row style={{ marginTop: "1rem" }}>
                <Col style={{ textAlign: "center" }}>
                    <Button id={"guest-submit-btn"} disabled={props.isLoading} style={{ width: "100%", margin: "1rem 0 1.2rem 0" }} name="Continue" variant={variant.Primary} onClick={props.onSubmit} />
                </Col>
            </Row>
        </Container>
    </Form>
);