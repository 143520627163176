import './App.css';
import { Amplify } from 'aws-amplify';
import { Routes } from './routes';
import { Provider } from 'react-redux';
import { persister, store } from './state';
import { PersistGate } from 'redux-persist/integration/react';
import ReactGA from "react-ga4";
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { Color, Path, getPrimaryColor } from './env';

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      redirectSignIn: window.location.protocol + "//" + window.location.host + Path.Login,
      redirectSignOut: window.location.protocol + "//" + window.location.host + Path.Login,
      responseType: 'token',
    }
  },
});

if (process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID && process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID !== "" && getCookieConsentValue() == "true") {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID!)
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <div className="App">
          <Routes />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            style={{ background: Color.White, color: Color.DarkGrey, borderTop: `1px solid ${Color.Grey}` }}
            buttonStyle={{ background: getPrimaryColor(), color: Color.White, fontSize: "16px" }}
            declineButtonStyle={{ background: Color.White, color: Color.Grey, fontSize: "16px" }}
            expires={150}
            onAccept={() => {
              if (process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID && process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID !== "" && getCookieConsentValue() == "true") {
                ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID!)
              }
            }}
            enableDeclineButton
          >
            We use cookies to improve your experience. Read our <a href={"https://ecomni.co.uk/cookie-policy.html"} target={"_blank"}>cookie policy</a>.
          </CookieConsent>
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
