import { FunctionComponent } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CiFaceFrown } from "react-icons/ci";
import { Color } from "../../env";

export interface ErrorProps {
    message: string
}

export const Error: FunctionComponent<ErrorProps> = (
    props
) => (
    <Card style={{ marginTop: "1rem", borderColor: Color.Red, color: Color.Red }}>
        <Card.Body>
            <Row>
                <Col xs={12}>
                    <Card.Text><CiFaceFrown size={25} style={{ marginRight: "1rem" }} />{props.message}</Card.Text>
                </Col>
            </Row>
        </Card.Body>
    </Card>
)

export default Error