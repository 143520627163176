import { Auth } from "aws-amplify";
import { Component, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { AccountAction, Address as AddressDto } from "../../state/types";
import { Card, Col, Container, Row } from "react-bootstrap";
import Address from "../address/Address";
import { Dispatch } from "redux";
import { Button, variant } from "../form/Button";
import { getPrimaryColor } from "../../env";

export interface SetAddressState {
    address?: AddressDto
}

export type AccountDispatch = Dispatch<AccountAction>;

export interface SetAddressProps extends RouteComponentProps {
    auth: typeof Auth;
    accountDispatch: AccountDispatch;
}

export default class SetAddress extends Component<SetAddressProps, SetAddressState> {

    constructor(props: SetAddressProps) {
        super(props);
        this.state = {}
    }

    handleAddressChange = (address: AddressDto): void => {
        this.setState({
            address: address,
        })
    }

    handleSubmit = (): void => {
        this.props.history.goBack()
    }

    render() {
        const props: SetAddressViewProps = {
            ...this.props,
            address: this.state.address,
            onChange: this.handleAddressChange,
            onSubmit: this.handleSubmit,
        };
        return <SetAddressView {...props} />
    }
}

export interface SetAddressViewProps extends RouteComponentProps {
    auth: typeof Auth;
    address?: AddressDto;
    accountDispatch: AccountDispatch;
    onChange: (address: AddressDto) => void;
    onSubmit: () => void;
}

export const SetAddressView: FunctionComponent<SetAddressViewProps> = (props) => (
    <Container fluid>
        <Row>
            <Col xs={12} md={8} xl={5} style={{ margin: "auto" }}>
                <Card>
                    <Card.Body>
                        <Card.Title style={{ textAlign: "center" }}>Just before you continue...</Card.Title>
                        <Card.Text style={{ textAlign: "center" }}>Please enter your delivery address</Card.Text>
                        <Address {...props} onSubmit={props.onChange} />
                        <Card.Text style={{ textAlign: "center", fontSize: 18, marginTop: "1rem" }}>Delivery Address:</Card.Text>
                        <Card style={{ margin: "1rem" }}>
                            <Card.Body>
                                <Card.Text style={{ textAlign: "center", fontWeight: 600, fontSize: 18, color: getPrimaryColor() }}>{(props.address) ? props.address.value : "Not set"}</Card.Text>
                            </Card.Body>
                        </Card>
                        <Button disabled={(!props.address)} style={{ width: "100%", marginTop: "1rem" }} onClick={props.onSubmit} variant={variant.Primary} name="Submit" />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
)

