import { Component, FormEvent, FunctionComponent, PureComponent } from "react";
import { Auth } from "aws-amplify";
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { RouteComponentProps } from "react-router";
import { connect } from "react-redux";
import { RootState } from "../../state";
import { DELETE_ITEM, BasketAction, BasketItem, BasketType, DELETE_ALL, Address as AddressDto, ADD_ITEM, AccountData, Account, AccountAction, UPDATE_ACCOUNT } from "../../state/types";
import { Dispatch } from "redux";
import { Alert, Button, Card, Col, Form, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import { Shop } from "../../state/types/shop";
import { Color, Path, Storage, getPrimaryColor } from "../../env";
import { Stripe, StripeConstructorOptions, StripeElements } from "@stripe/stripe-js";
import { DeliveryDate, IConfirmOrder, IInitPayment, ISetOrderDeliveryDetails, ISetUserOrderDeliveryDate, InitPaymentInput, OrderType, PaymentParameters, TimerangeSelector } from "../../client/core";
import { variant, Button as EButton } from "../form/Button";
import ReactMarkdown from "react-markdown";

type TParams = { shopId: string };

export type BasketDispatch = Dispatch<BasketAction>;

export type AccountDispatch = Dispatch<AccountAction>;

export type RouteCheckoutProps = RouteComponentProps<TParams>;

export interface SelectedDeliveryDate {
    day: string;
    interimTimeInDays: number;
    timeranges: TimerangeSelector;
}

export interface CheckoutProps extends RouteCheckoutProps {
    basketDispatch: BasketDispatch;
    type: BasketType;
    basket: BasketItem[];
    shop?: Shop;
    loadStripe: (
        publishableKey: string,
        options?: StripeConstructorOptions | undefined
    ) => Promise<Stripe | null>;
    initPaymentAPI: IInitPayment;
    updateOrderStatusAPI: IConfirmOrder;
    setUserOrderDeliveryDateAPI: ISetUserOrderDeliveryDate
    setOrderDeliveryDetailsAPI: ISetOrderDeliveryDetails
    auth: typeof Auth;
    account?: AccountData;
}

export enum Action {
    None,
    DeliveryDate,
}

export interface CheckoutState {
    error: string;
    paymentParams?: PaymentParameters;
    isLoading: boolean;
    action: Action;
    deliveryDetails: string;
    formResponse: Map<string, string>;
}

export class Checkout extends Component<
    CheckoutProps,
    CheckoutState
> {
    stripe: Stripe | null

    constructor(props: CheckoutProps) {
        super(props);
        this.stripe = null
        this.state = {
            error: "",
            isLoading: true,
            action: Action.None,
            deliveryDetails: "",
            formResponse: new Map()
        };
    }

    componentDidMount(): void {
        this.setState({
            isLoading: true
        })
        const items = this.filterItemsByShopId(this.props.shop?.id!, this.props.basket)
        this.props.auth.currentSession().then(sess => {
            return this.initPayment({
                identityToken: sess.getIdToken().getJwtToken(),
                items: items,
                shopID: this.props.shop?.id!,
                type: this.props.type as string as OrderType,
                addressId: this.props.account?.address?.metadata.id,
                shopAddressId: this.props.shop?.addressId,
            })
        }).catch(() => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            const daysElapsedSinceAccountUpdate = (Date.now() - (this.props.account?.lastUpdated || 0)) / 86400000
            if (!this.props.account?.email || !this.props.account.email.match(emailRegex) || daysElapsedSinceAccountUpdate > 1) {
                return this.props.history.push(Path.CheckoutAsGuest)
            }

            return this.initGuestPayment({
                items: items,
                shopID: this.props.shop?.id!,
                type: this.props.type as string as OrderType,
                addressId: this.props.account.address?.metadata.id,
                shopAddressId: this.props.shop?.addressId,
                email: this.props.account.email,
            })
        })
    }

    initPayment = (input: InitPaymentInput) => {
        this.props.initPaymentAPI.InitPayment(input).then(async response => {
            if (response.statusCode === 403) {
                localStorage.removeItem(Storage.IsLoggedIn)
                this.props.auth.signOut();
                return this.props.history.push(Path.CheckoutAsGuest)
            }

            if (response.statusCode !== 201) {
                return this.setState({
                    error: "Sorry, we are unable to take your order right now. Please try again later."
                })
            }

            if (response.body!.isOnlinePayment) {
                this.stripe = await this.props.loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!, {
                    stripeAccount: response.body?.accountId,
                })
            }

            this.setState({
                paymentParams: response.body,
            })
        }).catch(() => {
            return this.setState({
                error: "Sorry, we are unable to take your order right now. Please try again later."
            })
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    initGuestPayment = (input: InitPaymentInput) => {
        if (!input.addressId || input.addressId === "") {
            return this.props.history.push(Path.SetAddress)
        }

        this.props.initPaymentAPI.InitGuestPayment(input).then(async response => {
            if (response.statusCode === 400) {
                return this.setState({
                    error: `Sorry, we were unable to take your order because we don't have all the required information.`
                })
            }

            if (response.statusCode !== 201) {
                return this.setState({
                    error: "Sorry, we are unable to take you order right now. Please try again later."
                })
            }

            if (response.body!.isOnlinePayment) {
                this.stripe = await this.props.loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!, {
                    stripeAccount: response.body?.accountId,
                })
            }

            this.setState({
                paymentParams: response.body,
            })
        }).catch(() => {
            return this.setState({
                error: "Sorry, we are unable to take you order right now. Please try again later."
            })
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    handleDeliveryDetailsSubmit = async (): Promise<Error | undefined> => {
        if (this.state.deliveryDetails.trim() === "" && this.state.formResponse.size == 0) {
            return
        }

        let deliveryDetails: string = ""

        this.state.formResponse.forEach((value, name) => {
            deliveryDetails += `${name}: ${value}, `
        })

        deliveryDetails += `Additional: ${this.state.deliveryDetails.trim()}`

        const response = await this.props.setOrderDeliveryDetailsAPI.SetOrderDeliveryDetails({
            details: deliveryDetails,
            paymentID: this.state.paymentParams?.paymentId!
        })

        if (response instanceof Error) {
            return new Error("We're sorry, but we couldn't add your delivery details to the order. If you're having trouble and the details aren't required for delivery, you could try removing them before submitting. We apologize for any inconvenience and appreciate your understanding.")
        }

        if (response.statusCode !== 204) {
            return new Error("We're sorry, but we couldn't add your delivery details to the order. If you're having trouble and the details aren't required for delivery, you could try removing them before submitting. We apologize for any inconvenience and appreciate your understanding.")
        }
    }

    handleSubmit = async (elements: StripeElements | null) => {
        if (elements == null) {
            return
        }

        const detailResponse = await this.handleDeliveryDetailsSubmit()
        if (detailResponse instanceof Error) {
            return this.setState({
                error: detailResponse.message
            })
        }

        try {
            const result = await this.stripe!.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.protocol}//${window.location.host}${Path.PaymentConfirmation}`,
                },
            });

            if (result && result.error) {
                return this.setState({
                    isLoading: false,
                    error: `Unable to process payment with cause: ${result.error.message}`
                })
            }

            this.setState({
                isLoading: false
            })
            this.clearBasket();
        } catch (err) {
            return this.setState({
                isLoading: false,
                error: "Unable to process payment"
            })
        }
    }

    handlePayLater = async () => {
        this.setState({
            isLoading: true
        })

        const detailResponse = await this.handleDeliveryDetailsSubmit()
        if (detailResponse instanceof Error) {
            return this.setState({
                error: detailResponse.message
            })
        }

        this.props.updateOrderStatusAPI.ConfirmOrder({
            paymentID: this.state.paymentParams?.paymentId!,
        }).then(response => {
            if (response instanceof Error) {
                return this.setState({
                    error: "Sorry we could not process this order at this time. Please try again later."
                })
            }

            if (response.statusCode === 403 || response.statusCode === 401) {
                return this.props.auth.signOut().
                    finally(() => this.props.history.push(Path.Login))
            }

            if (response.statusCode !== 200) {
                return this.setState({
                    error: "Sorry we could not process this order at this time. Please try again later."
                })
            }

            this.clearBasket();
            return this.props.history.replace(Path.OrderConfirmation)
        }).finally(() => {
            this.setState({
                isLoading: false
            })
        })
    }

    clearBasket = () => {
        this.props.basketDispatch({
            type: DELETE_ALL,
        });
    };

    handleDeleteItem = (item: BasketItem): void => {
        this.props.basketDispatch({
            type: DELETE_ITEM,
            payload: {
                ...item
            }
        })
    }

    filterItemsByShopId = (id: string, items: BasketItem[]): BasketItem[] => {
        if (id === "") {
            return []
        }
        return items.filter(item => item.shopId === id)
    }

    handleNavigate = (path: string) => {
        this.props.history.push(path);
    };

    handleActionChange = (action: Action): void => {
        this.setState({
            action: action,
        })
    }

    handleDeliveryDetailsChange = (details: string): void => {
        this.setState({
            deliveryDetails: details,
        })
    }

    getSelectedDeliveryDate = (
        dates: DeliveryDate[] | undefined
    ): SelectedDeliveryDate | undefined => {
        if (!dates) {
            return;
        }
        for (let date of dates) {
            for (let time of date.timeranges) {
                if (time.selected) {
                    return {
                        day: date.day,
                        interimTimeInDays: Math.ceil(
                            date.interimTime / (3600 * 24)
                        ),
                        timeranges: {
                            start: time.start,
                            end: time.end,
                            selected: true,
                        },
                    };
                }
            }
        }
    };

    handleSelectDeliveryDate = (date: DeliveryDate, timerange: TimerangeSelector): void => {
        let dates: DeliveryDate[] = this.state.paymentParams?.deliveryDates!

        dates = dates.map(d => {
            const times = d.timeranges.map(t => {
                t.selected = false
                return t
            })
            d.timeranges = times
            return d
        })

        dates = dates.map(d => {
            if (d.day !== date.day) {
                return d
            }
            const times = d.timeranges.map(t => {
                if (t.start === timerange.start && t.end === timerange.end) {
                    t.selected = true
                }
                return t
            })
            d.timeranges = times
            return d
        })

        this.setState({
            paymentParams: {
                ...this.state.paymentParams!,
                deliveryDates: dates
            }
        })
    }

    handleDeliveryDateSubmit = (): void => {
        this.handleActionChange(Action.None)
        const selectedDate = this.getSelectedDeliveryDate(this.state.paymentParams?.deliveryDates!)
        if (!selectedDate || !this.state.paymentParams) {
            return this.setState({
                error: "Could not update delivery date."
            })
        }
        this.props.setUserOrderDeliveryDateAPI.SetUserOrderDeliveryDate({
            deliveryDate: {
                day: selectedDate.day,
                time: {
                    start: selectedDate.timeranges.start,
                    end: selectedDate.timeranges.end,
                }
            },
            paymentID: this.state.paymentParams.paymentId,
        }).then(response => {
            if (response instanceof Error || response.statusCode !== 204) {
                return this.setState({
                    error: "Could not update delivery date at this time."
                })
            }
        }).catch(() => {
            return this.setState({
                error: "Could not update delivery date at this time."
            })
        })
    }

    handleFormChange = (name: string, value: string): void => {
        const { formResponse } = this.state
        formResponse.set(name, value)
        this.setState({
            formResponse: formResponse,
        })
    }

    render() {
        const basketViewProps: CheckoutViewProps = {
            type: this.props.type,
            error: this.state.error,
            action: this.state.action,
            items: this.filterItemsByShopId(this.props.shop?.id || "", this.props.basket),
            shop: this.props.shop,
            stripe: this.stripe,
            paymentParams: this.state.paymentParams,
            formResponse: this.state.formResponse,
            onFormChange: this.handleFormChange,
            onDeliveryDetailsChange: this.handleDeliveryDetailsChange,
            onDeliveryDateSelect: this.handleSelectDeliveryDate,
            onNavigate: this.handleNavigate,
            onSubmit: this.handleSubmit,
            onPayLaterSubmit: this.handlePayLater,
            onActionChange: this.handleActionChange,
            onDeliveryDateSubmit: this.handleDeliveryDateSubmit,
        };
        if (this.state.isLoading) {
            return (
                <Row style={{ textAlign: "center" }}>
                    <Spinner style={{ margin: "auto", color: getPrimaryColor() }} animation="border" />
                </Row>
            )
        } else {
            if (this.state.error !== "") {
                return (
                    <CheckoutErrorView />
                )
            }

            if (this.state.paymentParams && this.state.paymentParams?.isOnlinePayment) {
                return (
                    <>
                        {(this.state.paymentParams && this.stripe) &&
                            <Elements stripe={this.stripe} options={{
                                clientSecret: this.state.paymentParams?.paymentClientSecret,
                                locale: "en-GB",
                            }}>
                                <PaymentCheckoutView selectedDeliveryDate={this.getSelectedDeliveryDate(this.state.paymentParams?.deliveryDates)} {...basketViewProps} />
                            </Elements>
                        }
                    </>
                )
            }
            return (
                <PayLaterCheckoutView selectedDeliveryDate={this.getSelectedDeliveryDate(this.state.paymentParams?.deliveryDates)} {...basketViewProps} />
            )
        }
    }
}

const mapState = (state: RootState) => ({
    type: state.basket.type,
    basket: state.basket.items,
    shop: state.shop.shop,
    account: state.account.data,
});

const connector = connect(mapState, {});

export default connector(Checkout);

export interface CheckoutErrorProps { }

export const CheckoutErrorView: FunctionComponent<CheckoutErrorProps> = (
    props
) => {
    return (
        <Row>
            <Col xs={12}>
                <Card>
                    <Card.Body>
                        <Card.Title style={{ textAlign: "center", marginBottom: "2rem" }}>Order system is currently offline</Card.Title>
                        <Card.Text>We apologize for the inconvenience, but our ordering system is currently offline. We are working to resolve the issue and expect the system to be up and running shortly. Please try again later.</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export interface CheckoutViewProps {
    type: BasketType,
    error: string;
    items: BasketItem[];
    action: Action
    shop?: Shop;
    stripe?: Stripe | null;
    paymentParams?: PaymentParameters
    selectedDeliveryDate?: SelectedDeliveryDate
    formResponse: Map<string, string>
    onFormChange: (name: string, value: string) => void;
    onDeliveryDetailsChange: (details: string) => void;
    onNavigate: (path: string) => void;
    onSubmit: (elements: StripeElements | null) => void;
    onPayLaterSubmit: () => void;
    onActionChange: (action: Action) => void;
    onDeliveryDateSelect: (date: DeliveryDate, timerange: TimerangeSelector) => void;
    onDeliveryDateSubmit: () => void;
}

export const PayLaterCheckoutView: FunctionComponent<CheckoutViewProps> = (
    props
) => {
    return (
        <Form onSubmit={(e: FormEvent) => {
            e.preventDefault()
            props.onPayLaterSubmit();
        }}>
            <Row>
                <Col xs={12} md={7}>
                    <Card>
                        <Card.Body>
                            <Card.Title style={{ marginBottom: "1rem" }}>Order now pay later</Card.Title>
                            {(props.shop?.paymentInstructions && props.shop.paymentInstructions !== "") ? (
                                <ReactMarkdown>{props.shop.paymentInstructions}</ReactMarkdown>
                            ) : (
                                <Card.Text>{props.shop?.name} has opted out of online payments. Instead you can pay in-store for collections or, for deliveries, will be provided with an invoice.</Card.Text>
                            )}

                        </Card.Body>
                    </Card>
                    {(props.shop?.paymentForm && props.shop.paymentForm.length > 0) && (
                        <Card>
                            <Card.Body>
                                <Card.Title style={{ marginBottom: "1rem" }}>Required Details</Card.Title>
                                <Form>
                                    {props.shop.paymentForm.map(element => (
                                        <Row>
                                            <Col xs={3}>
                                                <Form.Label style={{ fontWeight: 600 }}>{element.name}</Form.Label>
                                            </Col>
                                            <Col xs={9}>
                                                <Form.Control onChange={(e) => props.onFormChange(element.name, e.target.value)} style={{ borderColor: getPrimaryColor() }} type={element.type} />
                                            </Col>
                                        </Row>
                                    ))}
                                </Form>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
                <Col xs={12} md={5}>
                    <ShopContactView shop={props.shop!} />
                    {(props.selectedDeliveryDate && props.paymentParams?.deliveryDates && props.type === BasketType.Delivery) && <DeliveryView onDeliveryDetailsChange={props.onDeliveryDetailsChange} onSubmit={props.onDeliveryDateSubmit} onSelect={props.onDeliveryDateSelect} action={props.action} onActionChange={props.onActionChange} address={props.paymentParams.deliveryAddress || ""} selectedDeliveryDate={props.selectedDeliveryDate} deliveryDates={props.paymentParams?.deliveryDates} />}
                    <CheckoutSummaryView onNavigate={props.onNavigate} error={props.error} submitText="Pay Later" delivery={props.paymentParams?.deliveryFee || 0} service={props.paymentParams?.serviceFee || 0} total={props.paymentParams?.total || 0} items={props.items} />
                </Col>
            </Row>
        </Form>
    )
}

export const PaymentCheckoutView: FunctionComponent<CheckoutViewProps> = (
    props
) => {
    const elements = useElements()
    return (
        <Form onSubmit={(e: FormEvent) => {
            e.preventDefault()
            props.onSubmit(elements)
        }}>
            <Row>
                <Col xs={12} md={7}>
                    <Card>
                        <Card.Body>
                            <Card.Title style={{ textAlign: "center", marginBottom: "2rem" }}>Enter your payment details</Card.Title>
                            <PaymentElement />
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={5}>
                    <ShopContactView shop={props.shop!} />
                    {(props.selectedDeliveryDate && props.paymentParams?.deliveryDates && props.type === BasketType.Delivery) && <DeliveryView onDeliveryDetailsChange={props.onDeliveryDetailsChange} onSubmit={props.onDeliveryDateSubmit} onSelect={props.onDeliveryDateSelect} action={props.action} onActionChange={props.onActionChange} address={props.paymentParams.deliveryAddress} selectedDeliveryDate={props.selectedDeliveryDate} deliveryDates={props.paymentParams?.deliveryDates} />}
                    <CheckoutSummaryView onNavigate={props.onNavigate} error={props.error} delivery={props.paymentParams?.deliveryFee || 0} service={props.paymentParams?.serviceFee || 0} total={props.paymentParams?.total || 0} items={props.items} />
                </Col>
            </Row>
        </Form>
    )
}

export interface CheckoutSummaryViewProps {
    error: string;
    items: BasketItem[];
    service: number;
    delivery: number;
    total: number;
    submitText?: string;
    onNavigate: (path: string) => void;
}

export const CheckoutSummaryView: FunctionComponent<CheckoutSummaryViewProps> = (
    props
) => (
    <>
        {(!props.items || props.items.length == 0) && (
            <Card style={{ borderRadius: 0 }}>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card.Title>No items</Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card.Text>There are currently no items in your basket.</Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        )}
        <Card style={{ borderRadius: 0 }}>
            <Card.Body>
                <Card.Text style={{ fontWeight: 600 }}>Items</Card.Text>
                {props.items.map(item => (
                    <Row key={item.id}>
                        <Col>
                            <Card.Text>
                                <Card.Text>{item.name} ({item.volume}{(item.uom && item.uom !== "Unit") ? " " + item.uom : ""})</Card.Text>
                            </Card.Text>
                        </Col>
                        <Col style={{ textAlign: "right" }}>
                            <Card.Text>£{item.price.toFixed(2)}</Card.Text>
                        </Col>
                    </Row>
                ))}
                {(props.service > 0 || props.delivery > 0) && <hr />}
                {(props.service > 0) &&
                    <Row>
                        <Col>
                            <Card.Text>Service</Card.Text>
                        </Col>
                        <Col style={{ textAlign: "right" }}>
                            <Card.Text>£{props.service.toFixed(2)}</Card.Text>
                        </Col>
                    </Row>
                }
                {(props.delivery > 0) &&
                    <Row>
                        <Col>
                            <Card.Text>Delivery</Card.Text>
                        </Col>
                        <Col style={{ textAlign: "right" }}>
                            <Card.Text>£{props.delivery.toFixed(2)}</Card.Text>
                        </Col>
                    </Row>
                }
                <hr />
                <Row>
                    <Col>
                        <Card.Text>Total</Card.Text>
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                        <Card.Text>£{props.total.toFixed(2)}</Card.Text>
                    </Col>
                </Row>
                <Alert variant={"danger"} show={props.error != ""}>
                    {props.error}
                </Alert>
                <Button
                    style={{ marginTop: "1rem", width: "100%", background: getPrimaryColor(), border: 0, borderRadius: 0 }}
                    type="submit"
                >
                    {props.submitText || "Pay"}
                </Button>
                <EButton style={{ marginTop: "1rem", width: "100%", color: Color.Grey }} variant={variant.Transparent} onClick={() => props.onNavigate(Path.Basket)} name="Cancel" />
            </Card.Body>
        </Card>
    </>
)

export interface ShopContactViewProps {
    shop: Shop
}

export const ShopContactView: FunctionComponent<ShopContactViewProps> = (
    props
) => (
    <Card style={{ borderRadius: 0 }}>
        <Card.Body>
            <Row>
                <Col>
                    <Card.Title>{props.shop.name}</Card.Title>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card.Text>Address</Card.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <Card.Text>{props.shop.address}</Card.Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card.Text>Email</Card.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <Card.Text>{props.shop.email}</Card.Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card.Text>Phone</Card.Text>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    <Card.Text>{props.shop.phone}</Card.Text>
                </Col>
            </Row>
        </Card.Body>
    </Card>
)

export interface DeliveryViewProps {
    selectedDeliveryDate: SelectedDeliveryDate;
    address: string;
    deliveryDates: DeliveryDate[];
    action: Action;
    onDeliveryDetailsChange: (details: string) => void;
    onActionChange: (action: Action) => void;
    onSelect: (date: DeliveryDate, timerange: TimerangeSelector) => void;
    onSubmit: () => void;
}

export const DeliveryView: FunctionComponent<DeliveryViewProps> = (
    props
) => (
    <>
        <Modal show={props.action === Action.DeliveryDate} onHide={() => props.onSubmit()}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <ListGroup>
                    {props.deliveryDates.map(date => (
                        <>
                            {date.timeranges.map(time => (
                                <ListGroup.Item
                                    style={{ cursor: "pointer", padding: ".5rem", textAlign: "center", color: (time.selected) ? Color.White : Color.DarkGrey, background: (time.selected) ? getPrimaryColor() : Color.White }}
                                    onSelect={() => props.onSelect(date, time)}
                                    onClick={() => props.onSelect(date, time)}
                                >
                                    {date.day} ({time.start} - {time.end})
                                </ListGroup.Item>
                            ))}
                        </>
                    ))}
                </ListGroup>
                <EButton style={{ marginTop: "1rem", width: "100%" }} onClick={() => props.onSubmit()} variant={variant.Primary} name={"Submit"} />
            </Modal.Body>
        </Modal>
        <Card style={{ borderRadius: 0 }}>
            <Card.Body>
                <Row style={{ marginBottom: "1rem" }}>
                    <Col>
                        <Card.Text style={{ fontWeight: 600 }}>Delivery</Card.Text>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "1rem" }}>
                    <Col xs={3}>
                        <Card.Text>
                            <Card.Text>Address</Card.Text>
                        </Card.Text>
                    </Col>
                    <Col xs={9} style={{ textAlign: "right" }}>
                        <Card.Text>{props.address}</Card.Text>
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <Card.Text>
                            <Card.Text>Date</Card.Text>
                        </Card.Text>
                    </Col>
                    <Col xs={9} style={{ textAlign: "right" }}>
                        <Card.Text style={{ color: getPrimaryColor(), fontWeight: 600 }}>{props.selectedDeliveryDate.day} {props.selectedDeliveryDate.timeranges.start} - {props.selectedDeliveryDate.timeranges.end} ({props.selectedDeliveryDate.interimTimeInDays} days)<EButton style={{ marginLeft: "1rem" }} variant={variant.Primary} name={"Change"} color={Color.Primary} onClick={() => props.onActionChange(Action.DeliveryDate)} /></Card.Text>
                    </Col>
                </Row>
                <Row style={{ marginTop: "1rem" }}>
                    <Col xs={3}>
                        <Card.Text>
                            <Card.Text>Details</Card.Text>
                        </Card.Text>
                    </Col>
                    <Col xs={9}>
                        <Form.Group className="mb-3" controlId="delivery-details" placeholder="">
                            <Form.Control as="textarea" onChange={(e) => props.onDeliveryDetailsChange(e.target.value)} rows={3} />
                            <Form.Text id="delivery-details-help" muted>
                                Additional delivery details e.g. Flat number, codes, instructions
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </>
)