import { Component, FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import { Card, Col, Row } from "react-bootstrap";
import { Path, getPrimaryColor } from "../../env";
import { FaRegCheckCircle } from 'react-icons/fa';
import { Button, variant } from "../form/Button";
import { RootState } from "../../state";
import { connect } from "react-redux";
import { Journey } from "../../state/types";

export interface OrderConfirmationProps extends RouteComponentProps {
    journey?: Journey;
}

export interface OrderConfirmationState { }

export class OrderConfirmation extends Component<
    OrderConfirmationProps,
    OrderConfirmationState
> {
    constructor(props: OrderConfirmationProps) {
        super(props);
        this.state = {};
    }

    handleNavigate = (path: string) => {
        this.props.history.push(path);
    };

    render() {
        const basketViewProps: OrderConfirmationViewProps = {
            onNavigate: this.handleNavigate,
            journey: this.props.journey,
        };
        return <OrderConfirmationView {...basketViewProps} />
    }
}

const mapState = (state: RootState) => ({
    journey: state.journey.journey,
});

const connector = connect(mapState, {});

export default connector(OrderConfirmation);

export interface OrderConfirmationViewProps {
    journey?: Journey;
    onNavigate: (path: string) => void;
}

export const OrderConfirmationView: FunctionComponent<OrderConfirmationViewProps> = (
    props
) => {
    return (
        <Row>
            <Col style={{ margin: "auto" }} xs={12} md={8}>
                <Card>
                    <Card.Body style={{ textAlign: "center" }}>
                        <FaRegCheckCircle color={getPrimaryColor()} size={60} />
                        <Card.Title style={{ color: getPrimaryColor(), fontSize: "1.8rem", marginTop: "1rem", fontWeight: 400 }}>Order Complete</Card.Title>
                        <Card.Text style={{ fontSize: "1.2rem", marginTop: "1rem" }}>Your order has been processed.</Card.Text>
                        <Card.Text style={{ marginBottom: "1.3rem" }}>You'll receive an email shortly.</Card.Text>
                        <Button onClick={() => props.onNavigate(props.journey?.base || Path.Dashboard)} variant={variant.Primary} name="Continue" />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}